import { createWebHistory, createRouter } from "vue-router";
import HomePerjuangan from "@/views/Home";
import RoutePageIssuerBalance from "@/components/RoutePageIssuerBalance";
import AboutPerjuangan from "@/views/About";
import LoginScreen from "@/components/LoginScreen";
import VerifyOTPScreen from "@/components/VerifyOTPScreen";
import ChangePasswordScreen from "@/components/ChangePasswordScreen";
import DashboardRightContent from "@/components/DashboardRightContent";
import NotFound404 from "@/components/404notfound";
import RouterPageTransaction from "@/components/RouterPageTransaction";
import RouterPagePayout from "@/components/RouterPagePayout";
import RoutePageActivityLog from "@/components/RoutePageActivityLog";
import RoutePageRequestLog from "@/components/RoutePageRequestLog";
import RouteProductPrice from "@/components/RouteProductPrice";
import RoutePayoutLog from "@/components/RoutePayoutLog";
import RoutePgIncomingLog from "@/components/RoutePgIncomingLog";
import RoutePgLog from "@/components/RoutePgLog";
import RouterClientCrud from "@/components/RouterClientCrud";
import RouteUserProfile from "@/components/RouteUserProfile";
import RouteWhitelist from "@/components/RouteWhitelist";
import RouteMessages from "@/components/RouteMessages";
import RoutePageSetting from "@/components/RoutePageSetting";
import RoutePageBalanceHistory from "@/components/RoutePageBalanceHistory";
import RoutePageBalanceDisbursment from "@/components/RoutePageBalanceDisbursment";
import RoutePageBalanceWallet from "@/components/RoutePageBalanceWallet";
import RouterPageUsers from "@/components/RouterPageUsers";
import RouterPageGenerateVA from "@/components/RouterPageGenerateVA";
import RouteClientReport from "@/components/RouteClientReport";
import RouteMerchantList from "@/components/RouteMerchantList";
import RouteProductMapping from "@/components/RouteProductMapping";
import SettlementIndex from "@/views/settlement/Index";
import SettlementPage from "@/views/settlement/SettlementPage";
import SettlementForm from "@/views/settlement/Form";
import RouteMessageRead from "@/components/RouteMessageRead";
import RouterSummaryReport from "@/components/RouterSummaryReport";
import RouterLogViewer from "@/components/RouterLogViewer";
import RouteFeeCalculator from "@/components/RouteFeeCalculator";
import TopupRequestIndex from "@/views/topup/Index";
import TopupRequesList from "@/views/topup/List";
import TopupRequesForm from "@/views/topup/Form";
import RouteSettlementReport from "@/components/RouteSettlementReport";
import RouteE2Wallet from "@/components/RouteE2Wallet";
import RoutePageDistributeBalance from "@/components/RoutePageDistributeBalance";
import ProductIndex from "@/views/product/Index";
import ProductList from "@/views/product/List";
import ProductForm from "@/views/product/Form";
import TransactionChartPage from "@/views/transactions/TransactionChartPage";
import SimulatorPayinIndex from "@/views/simulator/payin/Index";
import SimulatorPayinForm from "@/views/simulator/payin/Form";
import SimulatorPayinResult from "@/views/simulator/payin/ResultForm";
import SimulatorPayoutIndex from "@/views/simulator/payout/Index";
import SimulatorPayoutForm from "@/views/simulator/payout/Form";
import SimulatorPayoutResult from "@/views/simulator/payout/ResultForm";
import SimulatorPayinNewIndex from "@/views/simulator-new/payin/Index";
import SimulatorPayinFormPage1 from "@/views/simulator-new/payin/FormPage1";
import SimulatorPayinFormPage2 from "@/views/simulator-new/payin/FormPage2";
import SimulatorPayinFormPage3 from "@/views/simulator-new/payin/FormPage3";
import SimulatorPayinNewResult from "@/views/simulator-new/payin/ResultForm";
import SimulatorPayoutNewIndex from "@/views/simulator-new/payout/Index";
import SimulatorPayoutFormPage1 from "@/views/simulator-new/payout/FormPage1";
import SimulatorPayoutFormPage2 from "@/views/simulator-new/payout/FormPage2";
import SimulatorPayoutFormPage3 from "@/views/simulator-new/payout/FormPage3";
import SimulatorManualDisbursementIndex from "@/views/simulator-new/manual-disbursement/Index";
import SimulatorManualDisbursementFormPage1 from "@/views/simulator-new/manual-disbursement/FormPage1";
import SimulatorManualDisbursementFormPage2 from "@/views/simulator-new/manual-disbursement/FormPage2";
import SimulatorManualDisbursementFormPage3 from "@/views/simulator-new/manual-disbursement/FormPage3";
import SimulatorCallbackRequestIndex from "@/views/simulator-new/callback-request/Index";
import SimulatorCallbackRequestFormPage1 from "@/views/simulator-new/callback-request/FormPage1";
import SimulatorTransactionCheckIndex from "@/views/simulator-new/transaction-check/Index";
import SimulatorTransactionCheckFormPage1 from "@/views/simulator-new/transaction-check/FormPage1";
import PartnerIndex from "@/views/partner/Index";
import PartnerList from "@/views/partner/List";
import PartnerForm from "@/views/partner/Form";
import PaymentPage from "@/views/payment/PaymentPage";
import PaymentSuccessPage from "@/views/payment/PaymentSuccessPage";
import PaymentFailedPage from "@/views/payment/PaymentFailedPage";
import PaymentRedirectPage from "@/views/payment/PaymentRedirectPage";
import PaymentVaPage from "@/views/payment-va/Index";
import PaymentWalletPage from "@/views/payment-wallet/index";
import ManageInformationIndex from "@/views/manage-information/Index";
import ManageInformationList from "@/views/manage-information/List";
import ManageInformationForm from "@/views/manage-information/Form";
import FailedPayinTrxIndex from "@/views/failed-payin/Index";
import OverdraftSummaryIndex from "@/views/overdraft-summary/Index";
import OverdraftSummaryList from "@/views/overdraft-summary/List";
import OverdraftSummaryForm from "@/views/overdraft-summary/Form";
import OverdraftHistoryIndex from "@/views/overdraft-history/Index";
import OverdraftHistoryList from "@/views/overdraft-history/List";

let routes = [
  {
    path: "/",
    name: "Home",
    component: HomePerjuangan,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: HomePerjuangan,
  },
  {
    path: "/issuer-balance",
    name: "Issuer Balance",
    component: RoutePageIssuerBalance,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginScreen,
  },
  {
    path: "/verify-otp",
    name: "Verify OTP",
    component: VerifyOTPScreen,
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePasswordScreen,
  },
  {
    path: "/about",
    name: "About",
    component: AboutPerjuangan,
  },
  {
    path: "/payment",
    name: "Payment",
    component: PaymentPage,
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: PaymentSuccessPage,
  },
  {
    path: "/payment-failed",
    name: "PaymentFailed",
    component: PaymentFailedPage,
  },
  {
    path: "/payment-redirect",
    name: "PaymentRedirect",
    component: PaymentRedirectPage,
  },
  {
    path: "/payment-va",
    name: "PaymentVa",
    component: PaymentVaPage,
  },
  {
    path: "/payment-wallet",
    name: "PaymentWallet",
    component: PaymentWalletPage,
  },
  {
    path: "/pgsetting",
    name: "PG Setting",
    component: RoutePageSetting,
  },
  {
    path: "/content",
    name: "Content",
    component: DashboardRightContent,
  },
  {
    path: "/logout",
    name: "Content",
    component: DashboardRightContent,
  },
  {
    path: "/textlogviewer",
    name: "Text Log Viewer",
    component: RouterLogViewer,
  },
  {
    path: "/payin-transaksi",
    name: "PayIn Transaksi",
    component: RouterPageTransaction,
  },
  {
    path: "/payout-transaksi",
    name: "PayOut Transaksi",
    component: RouterPagePayout,
  },
  {
    path: "/transaction-chart",
    name: "Transaction Chart",
    component: TransactionChartPage,
  },
  {
    path: "/generateva",
    name: "Generate VA",
    component: RouterPageGenerateVA,
  },
  {
    path: "/summaryreport",
    name: "Summary Report",
    component: RouterSummaryReport,
    props: {
      edit: false,
    },
  },
  {
    path: "/client",
    name: "Partners",
    component: RouterClientCrud,
    props: {
      edit: false,
    },
  },
  {
    path: "/partner",
    name: "Partner",
    component: PartnerIndex,
    children: [
      {
        path: "",
        name: "Partner List",
        component: PartnerList,
        // meta: {
        //     requiresAuth: true
        // }
      },
      {
        path: "add",
        name: "Partner Add",
        component: PartnerForm,
      },
      {
        path: ":id/view",
        name: "Partner Edit",
        component: PartnerForm,
      },
    ],
  },
  {
    path: "/client/:mode/:id/:code",
    name: "Partners Edit",
    component: RouterClientCrud,
    props: {
      edit: true,
    },
  },
  {
    path: "/balance-wallet",
    name: "Balance History - Wallet",
    component: RoutePageBalanceWallet,
  },
  {
    path: "/balance-disbursement",
    name: "Balance History - Disbursement",
    component: RoutePageBalanceDisbursment,
  },
  {
    path: "/balance-history",
    name: "Balance History",
    component: RoutePageBalanceHistory,
  },
  {
    path: "/messages",
    name: "Messages",
    component: RouteMessages,
  },
  {
    path: "/messages/read/:userid/:messageid",
    name: "Read Message",
    component: RouteMessageRead,
  },
  {
    path: "/settlement",
    name: "Settlement",
    component: SettlementIndex,
    children: [
      {
        path: "",
        name: "Settlement Page",
        component: SettlementPage,
        // meta: {
        //     requiresAuth: true
        // }
      },
      // {
      //     path: 'add',
      //     name: 'Settlement Add',
      //     component: TopupRequesForm
      // },
      {
        path: ":id/view",
        name: "Settlement View",
        component: SettlementForm,
      },
    ],
  },
  {
    path: "/settlement-report",
    name: "Settlement Report",
    component: RouteSettlementReport,
  },
  {
    path: "/webuser",
    name: "Users",
    component: RouterPageUsers,
  },
  {
    path: "/topuprequest",
    name: "Topup Request Data",
    component: TopupRequestIndex,
    children: [
      {
        path: "",
        name: "Topup Request List",
        component: TopupRequesList,
        // meta: {
        //     requiresAuth: true
        // }
      },
      {
        path: "add",
        name: "Topup Request Add",
        component: TopupRequesForm,
      },
      {
        path: ":id/view",
        name: "topuprequest-view",
        component: TopupRequesForm,
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: RouteUserProfile,
  },
  {
    path: "/whitelist",
    name: "Whitelist",
    component: RouteWhitelist,
  },
  {
    path: "/products",
    name: "Products",
    component: ProductIndex,
    children: [
      {
        path: "",
        name: "Product List",
        component: ProductList,
        // meta: {
        //     requiresAuth: true
        // }
      },
      {
        path: "add",
        name: "Product Add",
        component: ProductForm,
      },
      {
        path: ":id/:pgid/view",
        name: "Product Edit",
        component: ProductForm,
      },
    ],
  },
  {
    path: "/productmapping",
    name: "Product Mapping",
    component: RouteProductMapping,
  },
  {
    path: "/productprice",
    name: "Product Price",
    component: RouteProductPrice,
  },
  {
    path: "/activitylog",
    name: "Activity Log",
    component: RoutePageActivityLog,
  },
  {
    path: "/requestlog",
    name: "Request Log",
    component: RoutePageRequestLog,
  },
  {
    path: "/pgincominglog",
    name: "PG Incoming Log",
    component: RoutePgIncomingLog,
  },
  {
    path: "/payoutlog",
    name: "Payout Log",
    component: RoutePayoutLog,
  },
  {
    path: "/clientreport",
    name: "Client Report",
    component: RouteClientReport,
  },
  {
    path: "/pglog",
    name: "PG Log",
    component: RoutePgLog,
  },
  {
    path: "/merchantlist",
    name: "Merchant List",
    component: RouteMerchantList,
  },
  {
    path: "/checkwallet",
    name: "E2Pay Wallet",
    component: RouteE2Wallet,
  },
  {
    path: "/feecalculator",
    name: "Fee Calculator",
    component: RouteFeeCalculator,
  },
  {
    path: "/distribute-balance",
    name: "Distribute Balance",
    component: RoutePageDistributeBalance,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFound404,
  },
  {
    path: "/simulator-payin",
    name: "Simulator Old Payin",
    component: SimulatorPayinIndex,
    children: [
      {
        path: "",
        name: "Simulator Old Payin",
        component: SimulatorPayinForm,
      },
      {
        path: "result",
        name: "Simulator Old Payin Result",
        component: SimulatorPayinResult,
      },
    ],
  },
  {
    path: "/simulator-payout",
    name: "Simulator Old Payout",
    component: SimulatorPayoutIndex,
    children: [
      {
        path: "",
        name: "Simulator Old Payout",
        component: SimulatorPayoutForm,
      },
      {
        path: "result",
        name: "Simulator Old Payout Result",
        component: SimulatorPayoutResult,
      },
    ],
  },
  {
    path: "/simulator-new-payin",
    name: "Simulator Payin",
    component: SimulatorPayinNewIndex,
    children: [
      {
        path: "",
        name: "Simulator Payin Page 1",
        component: SimulatorPayinFormPage1,
      },
      {
        path: "",
        name: "Simulator Payin Page 2",
        component: SimulatorPayinFormPage2,
      },
      {
        path: "",
        name: "Simulator Payin Page 3",
        component: SimulatorPayinFormPage3,
      },
      {
        path: "result",
        name: "Simulator Payin Result",
        component: SimulatorPayinNewResult,
      },
    ],
  },
  {
    path: "/simulator-new-payout",
    name: "Simulator Payout",
    component: SimulatorPayoutNewIndex,
    children: [
      {
        path: "",
        name: "Simulator Payout Page 1",
        component: SimulatorPayoutFormPage1,
      },
      {
        path: "",
        name: "Simulator Payout Page 2",
        component: SimulatorPayoutFormPage2,
      },
      {
        path: "",
        name: "Simulator Payout Page 3",
        component: SimulatorPayoutFormPage3,
      },
      {
        path: "result",
        name: "Simulator Payin Result",
        component: SimulatorPayinNewResult,
      },
    ],
  },
  {
    path: "/simulator-manual-disbursement",
    name: "Simulator Manual Disbursement",
    component: SimulatorManualDisbursementIndex,
    children: [
      {
        path: "",
        name: "Manual Disbursement Page",
        component: SimulatorManualDisbursementFormPage1,
      },
      {
        path: "",
        name: "Manual Disbursement Confirmation Page",
        component: SimulatorManualDisbursementFormPage2,
      },
      {
        path: "",
        name: "Manual Disbursement Notification Page",
        component: SimulatorManualDisbursementFormPage3,
      },
    ],
  },
  {
    path: "/simulator-callback-request",
    name: "Callback Request",
    component: SimulatorCallbackRequestIndex,
    children: [
      {
        path: "",
        name: "Callback Request Form",
        component: SimulatorCallbackRequestFormPage1,
      },
    ],
  },
  {
    path: "/check-transaction",
    name: "Check Transaction ",
    component: SimulatorTransactionCheckIndex,
    children: [
      {
        path: "",
        name: "Check Transaction Form",
        component: SimulatorTransactionCheckFormPage1,
      },
    ],
  },
  {
    path: "/manage-information",
    name: "Manage Informations",
    component: ManageInformationIndex,
    children: [
      {
        path: "",
        name: "Manage Information List",
        component: ManageInformationList,
      },
      {
        path: "add",
        name: "Manage Information Add",
        component: ManageInformationForm,
      },
      {
        path: ":id/edit",
        name: "Manage Information Edit",
        component: ManageInformationForm,
      },
    ],
  },
  {
    path: "/failed-payin-transaksi",
    name: "Failed PayIn Trx",
    component: FailedPayinTrxIndex,
  },
  {
    path: "/overdraft-summary",
    name: "Overdraft Summary",
    component: OverdraftSummaryIndex,
    children: [
      {
        path: "",
        name: "Overdraft Summary List",
        component: OverdraftSummaryList,
      },
      {
        path: "add",
        name: "Overdraft Summary Add",
        component: OverdraftSummaryForm,
      },
      // {
      //     path: ':id/edit',
      //     name: 'Manage Information Edit',
      //     component: ManageInformationForm
      // }
    ],
  },
  {
    path: "/overdraft-history",
    name: "Overdraft History",
    component: OverdraftHistoryIndex,
    children: [
      {
        path: "",
        name: "Overdraft History List",
        component: OverdraftHistoryList,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active text-success font-weight-bold",
  linkExactActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/assets",
    "/login",
    "/verify-otp",
    // "/change-password",
    "/register",
    "/payment",
    "/payment-success",
    "/payment-failed",
    "/payment-redirect",
    "/payment-va",
    "/payment-wallet",
  ];
  const guestPages = [
    "/about",
    "/messages",
    "/register",
    "/payment",
    "/payment-success",
    "/payment-failed",
    "/payment-redirect",
    "/payment-va",
    "/payment-wallet",
  ];
  const userPages = [
    "/about",
    "/messages",
    "/register",
    "/payment",
    "/payment-success",
    "/payment-failed",
    "/profile",
    "/home",
    "/dashboard",
    "/payin-transaksi",
    "/payout-transaksi",
    "/balance",
    "/balance-wallet",
    "/balance-disbursement",
    "/balance-history",
    "/settlement",
    "/transaction-chart",
    "/payment-redirect",
    "/payment-va",
    "/payment-wallet",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  let role = undefined;
  if (loggedIn != null) {
    let dataUser = JSON.parse(loggedIn);
    role = dataUser.role;
  }
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    // if(role==='guest' && !guestPages.includes(to.path)){
    //     next('/about');
    // }else if(role==='user' && !userPages.includes(to.path)){
    //     next('/payin-transaksi');
    // }else {
    //     next();
    // }
    let basePath = to.path.split("/");
    if (role === "guest" && !guestPages.includes("/" + basePath[1])) {
      next("/about");
    } else if (role === "user" && !userPages.includes("/" + basePath[1])) {
      // next('/payin-transaksi');
      next("/dashboard");
    } else {
      next();
    }
  }
});

export default router;
