<template>
    <div class="modal fade" ref="crypto-modal" :id="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalMessageTitle" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crypto Form</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group"> 
                            <label class="form-control-label">Tanggal Penarikan</label>
                            <VueDatePicker v-model="formData.withdrawal_date" format="dd-MM-yyyy" :disabled="readonly" />
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Merchant Name</label>
                            <input type="text" class="form-control" disabled="disabled" :value="partnerName" placeholder="Partner Name">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Wallet Address TRC20</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" disabled v-model="formData.wallet_address_trc20">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary m-0" type="button" @click="copyToClipBoard(formData.wallet_address_trc20)">Copy</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Crypto Currency Rate (IDR)</label>
                            <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.currency_rate_in_idr)"  
                             @input="event => this.formData.currency_rate_in_idr = getterNumeric(event.target.value)">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Total USDT Buy</label>
                            <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.total_usdt_buy_amt)"  
                             @input="event => this.formData.total_usdt_buy_amt = getterNumeric(event.target.value)">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Total Buying Crypto (IDR)</label>
                            <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.total_buying_crypto_amt)"  
                             @input="event => this.formData.total_buying_crypto_amt = getterNumeric(event.target.value)">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Crypto Settlement Fee</label>
                            <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.crypto_settlement_fee)"  
                             @input="event => this.formData.crypto_settlement_fee = getterNumeric(event.target.value)">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Total Settlement Charge To Merchant</label>
                            <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.settlement_charge_to_merchant)"  
                             @input="event => this.formData.settlement_charge_to_merchant = getterNumeric(event.target.value)">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Crypto Sent To Merchant</label>
                            <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.crypto_sent_to_merchant)"  
                             @input="event => this.formData.crypto_sent_to_merchant = getterNumeric(event.target.value)">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Hash TRX</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" :readonly="readonly" :disabled="readonly" :value="setterNumeric(formData.hash_trx)"  
                                 @input="event => this.formData.hash_trx = getterNumeric(event.target.value)">
                                 <div class="input-group-append">
                                    <button class="btn btn-secondary m-0" type="button" @click="copyToClipBoard(formData.hash_trx)">Copy</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Status</label>
                            <select class="form-control" :readonly="readonly" :disabled="readonly" v-model=formData.status aria-label="Default select example">
                                <option selected>Pilih Jenis Transaksi</option>
                                <option value="1">Approved</option>
                                <option value="2">Waiting Approval</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer" v-if="!readonly">
                    <button type="button" class="btn bg-gradient-secondary" id="closeModal" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn bg-gradient-primary" @click="onSubmit">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {formatNumber, getFormattedNumber} from "@/services/number-format"
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    name: "ModalCryptoForm",
    props:{
        modalId: String,
        partnerName: String,
        formCryptoDetail: Object,
        readonly: Boolean
    },
    components: {
        VueDatePicker
    },
    data:()=>({
        formData: {
            withdrawal_date: '',
            currency_rate_in_idr: 0,
            total_usdt_buy_amt: 0,
            total_buying_crypto_amt: 0,
            crypto_settlement_fee: 0,
            settlement_charge_to_merchant: 0,
            crypto_sent_to_merchant: 0,
            hash_trx: 0,
            status_id: 1,
            evidence: "",
            wallet_address_trc20: "",
        }
    }),
    emits:['submit'],
    methods: {
        setterNumeric(val) {
            return formatNumber(val);
        },
        getterNumeric(val) {
            return getFormattedNumber(val);
        },
        onSubmit() {
            this.$emit('submit', this.formData);
            document.getElementById('closeModal').click();
        },
        copyToClipBoard(textToCopy){
            navigator.clipboard.writeText(textToCopy);      
        },
    },
    watch: {
        formCryptoDetail: function(newVal) { 
            if (newVal != null) {
                this.formData.wallet_address_trc20 = newVal.wallet_address_trc20;
                this.formData.withdrawal_date = newVal.withdrawal_date;
                this.formData.total_usdt_buy_amt = newVal.total_usdt_buy_amt;
                this.formData.total_buying_crypto_amt = newVal.total_buying_crypto_amt;
                this.formData.settlement_charge_to_merchant = newVal.settlement_charge_to_merchant;
                this.formData.crypto_sent_to_merchant = newVal.crypto_sent_to_merchant;
            }
        }
    }
}
</script>