<template>
    <DashboardLeftMenu></DashboardLeftMenu>
    <main class="main-content">
      <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
      <router-view :item="item" />
    </main>
  </template>
  
  <script>
  import DashboardLeftMenu from "@/components/DashboardLeftMenu";
  import NavigationTop from "@/components/NavigationTop";
  export default {
    name: "PartnerIndex",
    components: {
      DashboardLeftMenu,
      NavigationTop,
    },
    props: {
      item: Object
    },
    computed:{
      currentRouteName() {
        return this.$route.name;
      }
    },
  }
  </script>
  
  <style scoped>
  </style>