import UserService from "./user.service";

export default function authHeader() {
  let token = UserService.getUserToken();

  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
