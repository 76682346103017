<template>
  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid py-4">
      <div v-if="loading">
        <div class="row">
          <div class="col-xl-6" style="margin-bottom: 24px">
            <IssuerBalanceItem :loading="loading"></IssuerBalanceItem>
          </div>
          <div class="col-xl-6" style="margin-bottom: 24px">
            <IssuerBalanceItem :loading="loading"></IssuerBalanceItem>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6" style="margin-bottom: 24px">
            <IssuerBalanceItem :loading="loading"></IssuerBalanceItem>
          </div>
          <div class="col-xl-6" style="margin-bottom: 24px">
            <IssuerBalanceItem :loading="loading"></IssuerBalanceItem>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-center mb-5">
          Issuer Balance as per {{ currentTimestamp }}
        </p>
        <div class="row">
          <div
            v-for="(item, index) in balanceIssuerDataRow1"
            :key="index"
            class="col-xl-6"
            style="margin-bottom: 24px"
          >
            <IssuerBalanceItem :item="item"></IssuerBalanceItem>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(item, index) in balanceIssuerDataRow2"
            :key="index"
            class="col-xl-6"
            style="margin-bottom: 24px"
          >
            <IssuerBalanceItem :item="item"></IssuerBalanceItem>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import NavigationTop from "@/components/NavigationTop";
import DashboardService from "@/services/dashboard.service";
import IssuerBalanceItem from "@/views/IssuerBalanceItem";

export default {
  name: "IssuerBalanceRightContent",
  components: {
    NavigationTop,
    IssuerBalanceItem,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentTimestamp() {
      const date = new Date();

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const formattedDate = `${date.getDate()}-${
        months[date.getMonth()]
      }-${date.getFullYear()} ${date.getHours()}:${String(
        date.getMinutes()
      ).padStart(2, "0")}`;

      return formattedDate;
    },
  },
  methods: {
    getBalanceIssuer() {
      this.loading = true;
      DashboardService.getIssuerBalance().then((response) => {
        this.balanceIssuerDataRow1 = response.data.data?.slice(0, 2);
        this.balanceIssuerDataRow2 = response.data.data?.slice(2, 4);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getBalanceIssuer();
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    loading: false,
    balanceIssuerDataRow1: [],
    balanceIssuerDataRow2: [],
  }),
};
</script>

<style scoped></style>
