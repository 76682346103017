<template>
    <v-row justify="center">
      <button @click="dialog = true" class="form-control btn bg-gradient-dark mb-0">Submit</button>
        <v-dialog
        v-model="dialog"
        persistent
        height="auto"
        width="70%"
        >
          <v-card>
            <v-card-title class="text-h5">
            Are you sure to request this settlement ?
            </v-card-title>
            <v-card-text>
              <v-row class="mt-2">
                <v-col >
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">Inputted Amount/Gross Amount</p>
                </v-col>
                <v-col >
                  <p class="text-subtitle-1">Admin Fee</p>
                </v-col>
                <v-col >
                  <p class="text-subtitle-1">Net Amount</p>
                </v-col>
              </v-row>
              <v-row v-if="splitPayout == 1">
                <v-col >
                  <p class="text-subtitle-1">Wallet</p>
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_wallet_gross_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_wallet_fee_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_wallet}}</p>  
                </v-col>
              </v-row>
              <v-row v-if="splitPayout == 1">
                <v-col >
                  <p class="text-subtitle-1">Disbursement</p>
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_disburesement_gross_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_disburesement_fee_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_disburesement}}</p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <p class="text-subtitle-1">Local Bank</p>
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_local_bank_gross_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_local_bank_fee_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_local_bank}}</p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <p class="text-subtitle-1">Crypto</p>
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_crypto_gross_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_crypto_fee_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_crypto}}</p>  
                </v-col>
              </v-row>
              <v-row>
                <v-col >
                  <p class="text-subtitle-1">Overdraft</p>
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_overdraft_gross_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_overdraft_fee_amt}}</p>  
                </v-col>
                <v-col>
                  <p class="text-subtitle-1">{{modelParam.req_overdraft}}</p>  
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green-darken-1"
                variant="text"
                @click="close"
            >
                No
            </v-btn>
            <v-btn
                color="green-darken-1"
                variant="text"
                @click="ok"
            >
                Yes
            </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
  name: "ConfirmationDialogButton",
  props: {
    modelParam: Object,
    splitPayout: Number
  },
  emits: ["submit"],
  methods: {
    close() {
        this.dialog = false;
    },
    ok() {
      this.dialog = false;
      this.$emit('submit');
    } 
  },
  data:()=>({
    dialog: false
  }),
  watch: {
    modelParam: {
     handler(){

      },
      deep: true
    }
  }
}
</script>