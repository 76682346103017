<template>
  <DashboardLeftMenu></DashboardLeftMenu>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
export default {
  name: "RouteE2Wallet",
  components:{
    DashboardLeftMenu,
  }
}
</script>

<style scoped>

</style>