<template>
  <span
    v-if="loading"
    class="spinner-border spinner-border-sm text-info"
  ></span>
  <div class="card card-frame" v-else>
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">Manual Disbursement Form</h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white"
              ><strong>Error!</strong> {{ errorMsg }}</span
            >
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Issuer Merchant</label>
              <VueMultiselect
                :multiple="false"
                :class="{ 'is-invalid': v$.form.issuerMerchantCode.$error }"
                v-model="form.issuerMerchantCode"
                :options="refIssuerMerchants"
                :allow-empty="false"
                label="name"
                track-by="code"
                placeholder="Pick Issuer Merchant"
              >
              </VueMultiselect>
              <span
                class="invalid-feedback"
                v-if="v$.form.issuerMerchantCode.$error"
                >{{ v$.form.issuerMerchantCode.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label
                class="form-control-label"
                :class="{ 'is-invalid': v$.form.partnerId.$error }"
                >NST Merchant</label
              >
              <VueMultiselect
                :multiple="false"
                :class="{ 'is-invalid': v$.form.partnerId.$error }"
                v-model="form.partnerId"
                :options="refNstMerchants"
                :disabled="isDisabledNst"
                label="name"
                track-by="code"
                placeholder="Pick NST Merchant"
              >
              </VueMultiselect>
              <span class="invalid-feedback" v-if="v$.form.partnerId.$error">{{
                v$.form.partnerId.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Bank Name</label>
              <VueMultiselect
                :multiple="false"
                :class="{ 'is-invalid': v$.form.paymentId.$error }"
                v-model="form.paymentId"
                :options="refChannels"
                label="product_name"
                track-by="payment_id"
                placeholder="Pick Bank"
              >
              </VueMultiselect>
              <span class="invalid-feedback" v-if="v$.form.paymentId.$error">{{
                v$.form.paymentId.$errors[0].$message
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label"
                >Bank Account No./Mobile Number</label
              >
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': v$.form.customerId.$error }"
                v-model="form.customerId"
              />
              <span class="invalid-feedback" v-if="v$.form.customerId.$error">{{
                v$.form.customerId.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Receiver Name</label>
              <input
                type="text"
                :class="{ 'is-invalid': v$.form.CustomerName.$error }"
                class="form-control"
                v-model="form.CustomerName"
              />
              <span
                class="invalid-feedback"
                v-if="v$.form.CustomerName.$error"
                >{{ v$.form.CustomerName.$errors[0].$message }}</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Issuer</label>
              <VueMultiselect
                :multiple="false"
                v-model="form.IssuerCode"
                :class="{ 'is-invalid': v$.form.IssuerCode.$error }"
                :options="refIssuer"
                label="name"
                track-by="code"
                placeholder="Pick Issuer"
              >
              </VueMultiselect>
              <span class="invalid-feedback" v-if="v$.form.IssuerCode.$error">{{
                v$.form.IssuerCode.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Amount</label>
              <input
                type="number"
                :class="{ 'is-invalid': v$.form.amount.$error }"
                class="form-control"
                v-model="form.amount"
              />
              <span class="invalid-feedback" v-if="v$.form.amount.$error">{{
                v$.form.amount.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Remarks</label>
              <textarea
                rows="3"
                class="form-control"
                v-model="form.remarks"
                placeholder="remarks"
              >
              </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Setting Fee</label>
              <v-radio-group v-model="form.SettingFeeFlag" inline>
                <v-radio label="No" color="orange" :value="0"></v-radio>
                <v-radio label="Yes" color="indigo" :value="1"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
        <div v-if="form.SettingFeeFlag == 1">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Toppay Fee</label>
                <input
                  type="number"
                  :class="{ 'is-invalid': v$.form.ToppayFee.$error }"
                  placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)"
                  class="form-control"
                  v-model="form.ToppayFee"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ToppayFee.$error"
                  >{{ v$.form.ToppayFee.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Toppay Fee Model</label>
                <VueMultiselect
                  :multiple="false"
                  v-model="form.ToppayFeeModel"
                  :class="{ 'is-invalid': v$.form.ToppayFeeModel.$error }"
                  :options="refFOrP"
                  label="name"
                  track-by="code"
                  placeholder="Pick Model"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ToppayFeeModel.$error"
                  >{{ v$.form.ToppayFeeModel.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Toppay Security Fee</label>
                <input
                  type="number"
                  :class="{ 'is-invalid': v$.form.ToppaySFee.$error }"
                  placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)"
                  class="form-control"
                  v-model="form.ToppaySFee"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ToppaySFee.$error"
                  >{{ v$.form.ToppaySFee.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label"
                  >Toppay Security Fee Model</label
                >
                <VueMultiselect
                  :multiple="false"
                  v-model="form.ToppaySFeeModel"
                  :class="{ 'is-invalid': v$.form.ToppaySFeeModel.$error }"
                  :options="refFOrP"
                  label="name"
                  track-by="code"
                  placeholder="Pick Model"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ToppaySFeeModel.$error"
                  >{{ v$.form.ToppaySFeeModel.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Referral Fee</label>
                <input
                  type="number"
                  :class="{ 'is-invalid': v$.form.ReferralFee.$error }"
                  placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)"
                  class="form-control"
                  v-model="form.ReferralFee"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ReferralFee.$error"
                  >{{ v$.form.ReferralFee.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Referral Fee Model</label>
                <VueMultiselect
                  :multiple="false"
                  v-model="form.ReferralFeeModel"
                  :class="{ 'is-invalid': v$.form.ReferralFeeModel.$error }"
                  :options="refFOrP"
                  label="name"
                  track-by="code"
                  placeholder="Pick Model"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ReferralFeeModel.$error"
                  >{{ v$.form.ReferralFeeModel.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Referral Security Fee</label>
                <input
                  type="number"
                  :class="{ 'is-invalid': v$.form.ReferralSFee.$error }"
                  placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)"
                  class="form-control"
                  v-model="form.ReferralSFee"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ReferralSFee.$error"
                  >{{ v$.form.ReferralSFee.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label"
                  >Referral Security Fee Model</label
                >
                <VueMultiselect
                  :multiple="false"
                  v-model="form.ReferralSFeeModel"
                  :class="{ 'is-invalid': v$.form.ReferralSFeeModel.$error }"
                  :options="refFOrP"
                  label="name"
                  track-by="code"
                  placeholder="Pick Model"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.ReferralSFeeModel.$error"
                  >{{ v$.form.ReferralSFeeModel.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Supplier Fee</label>
                <input
                  type="number"
                  :class="{ 'is-invalid': v$.form.SupplierFee.$error }"
                  placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)"
                  class="form-control"
                  v-model="form.SupplierFee"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.SupplierFee.$error"
                  >{{ v$.form.SupplierFee.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Supplier Fee Model</label>
                <VueMultiselect
                  :multiple="false"
                  v-model="form.SupplierFeeModel"
                  :class="{ 'is-invalid': v$.form.SupplierFeeModel.$error }"
                  :options="refFOrP"
                  label="name"
                  track-by="code"
                  placeholder="Pick Model"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.SupplierFeeModel.$error"
                  >{{ v$.form.SupplierFeeModel.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label">Supplier Security Fee</label>
                <input
                  type="number"
                  :class="{ 'is-invalid': v$.form.SupplierSFee.$error }"
                  placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)"
                  class="form-control"
                  v-model="form.SupplierSFee"
                />
                <span
                  class="invalid-feedback"
                  v-if="v$.form.SupplierSFee.$error"
                  >{{ v$.form.SupplierSFee.$errors[0].$message }}</span
                >
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="form-control-label"
                  >Supplier Security Fee Model</label
                >
                <VueMultiselect
                  :multiple="false"
                  v-model="form.SupplierSFeeModel"
                  :class="{ 'is-invalid': v$.form.SupplierSFeeModel.$error }"
                  :options="refFOrP"
                  label="name"
                  track-by="code"
                  placeholder="Pick Model"
                >
                </VueMultiselect>
                <span
                  class="invalid-feedback"
                  v-if="v$.form.SupplierSFeeModel.$error"
                  >{{ v$.form.SupplierSFeeModel.$errors[0].$message }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="next()"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import useSimulatorFormStore from "@/store/simulator.form";
import { required, requiredIf } from "@vuelidate/validators";

export default {
  name: "SimulatorManualDisbursementFormPage1",
  mounted() {
    this.fetchData();
    if (this.$route.query.errorMsg) {
      this.isError = true;
      this.errorMsg = this.$route.query.errorMsg;
    }

    this.form.issuerMerchantCode = { code: "NST", name: "NST" };
  },
  methods: {
    fetchData() {
      this.loading = true;
      RequestService.postRequest(e2payApis.GET_LINKED_PARTNER, {}).then(
        (response) => {
          if (response.data.code == 1) {
            this.refNstMerchants = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.isError = true;
            this.errorMsg = response.data.message;
          }
        }
      );
    },
    next() {
      this.v$.form.$validate();
      if (!this.v$.form.$error) {
        this.form.SettingFeeFlag = parseInt(this.form.SettingFeeFlag);
        this.loading = true;
        RequestService.postRequest(e2payApis.GET_FEE, this.form).then(
          (response) => {
            if (response.data != null) {
              this.loading = false;
              this.form.adminFee = response.data;
              this.simulatorForm.setForm(this.form);
              this.$router.push({
                name: "Manual Disbursement Confirmation Page",
              });
            } else {
              this.loading = false;
              this.isError = true;
              this.errorMsg = "Admin Fee Not Found";
            }
          }
        );
      }
    },
  },
  data: () => ({
    v$: useValidate(),
    simulatorForm: useSimulatorFormStore(),
    loading: true,
    registerMessage: "",
    isError: false,
    errorMsg: "",
    status: "",
    refChannels: [],
    refIssuerMerchants: [
      { code: "NST", name: "NST" },
      { code: "Non-NST", name: "Non-NST" },
    ],
    refNstMerchants: [],
    refCurrencies: ["IDR"],
    refLanguages: ["ENG"],
    refFOrP: [
      { code: "F", name: "Fixed (F)" },
      { code: "P", name: "Percentage (P)" },
    ],
    refIssuer: [
      { code: "DVI", name: "DVI" },
      { code: "E2Pay", name: "E2Pay" },
      { code: "Nextrans", name: "Nextrans" },
      { code: "LinkQu", name: "LinkQu" },
      { code: "Payhere", name: "Payhere" },
    ],
    isDisabledNst: false,
    form: {
      paymentId: "",
      issuerMerchantCode: "",
      partnerId: "",
      amount: "",
      IssuerCode: "",
      customerId: "",
      CustomerName: "",
      remarks: "",
      mode: "payout",
      adminFee: 0,
      SettingFeeFlag: 0,
      ToppayFee: "",
      ToppayFeeModel: "",
      ToppaySFee: "",
      ToppaySFeeModel: "",
      ReferralFee: null,
      ReferralFeeModel: "",
      ReferralSFee: null,
      ReferralSFeeModel: "",
      SupplierFee: null,
      SupplierFeeModel: "",
      SupplierSFee: null,
      SupplierSFeeModel: "",
      Signature: "0r2iur4saIcECrvV0t/8cqO0cYo=",
    },
  }),
  validations() {
    return {
      form: {
        issuerMerchantCode: { required },
        paymentId: { required },
        customerId: { required },
        CustomerName: { required },
        partnerId: { required },
        amount: { required },
        IssuerCode: { required },
        ToppayFee: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ToppayFeeModel: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ToppaySFee: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ToppaySFeeModel: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ReferralFee: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ReferralFeeModel: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ReferralSFee: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        ReferralSFeeModel: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        SupplierFee: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        SupplierFeeModel: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        SupplierSFee: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
        SupplierSFeeModel: {
          requiredIfSettingFeeFlag: requiredIf(this.form.SettingFeeFlag == 1),
        },
      },
    };
  },
  watch: {
    "form.issuerMerchantCode": function (newVal) {
      if (newVal.code == "NST") {
        this.form.partnerId = "";
        this.isDisabledNst = false;
      } else {
        this.isDisabledNst = true;
        this.form.partnerId = {
          code: "42",
          mchCode: "Non-NST",
          name: "Non-NST",
        };
      }
    },
    "form.partnerId": function (newVal) {
      RequestService.postRequest(e2payApis.GET_LINKED_CHANNEL, {
        type: "payout",
        partnerId: newVal.code,
      }).then((response) => {
        if (response.data.code == 1) {
          this.refChannels = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = response.data.message;
        }
      });
    },
  },
};
</script>
<style>
.is-invalid .multiselect__tags {
  border-color: #fd5c70 !important;
  padding-right: unset;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 1rem; */
}
</style>
