<template>
  <div class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid">
      <div
        class="page-header min-height-300 border-radius-xl mt-4"
        style="
          background-image: url('../assets/img/curved-images/curved0.jpg');
          background-position-y: 50%;
        "
      >
        <span class="mask bg-gradient-primary opacity-6"></span>
      </div>
      <div class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                :src="img_profile"
                alt="profile_image"
                class="w-100 border-radius-lg shadow-sm"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="h-100">
              <h5 class="mb-1">
                {{ getFullName() }}
              </h5>
              <p class="mb-0 font-weight-bold text-sm">
                {{ getEmailAddress() }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="message && status"
        class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden"
      >
        <div
          v-if="status == 422"
          class="alert alert-danger fade show"
          role="alert"
        >
          <div class="d-flex align-items-center justify-content-between">
            {{ message }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div
          v-if="status == 200"
          class="alert alert-success fade show"
          role="alert"
        >
          <div class="d-flex align-items-center justify-content-between">
            {{ message }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden">
        <div class="row g-4 justify-content-between">
          <div class="col-auto">
            <ProfileChangePassword></ProfileChangePassword>
          </div>
          <div class="col-auto">
            <div
              class="form-group d-flex align-items-center justify-content-between mb-0"
            >
              <span class="text-sm text-bold">Google 2FA Status</span>
              <div class="form-check form-switch ms-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="gaValidationFlag"
                  @change="handleGASwitchChange"
                  true-value="1"
                  false-value="0"
                  :disabled="isLoading2FA"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12 col-xl-4">
          <div class="card">
            <div class="card-header pb-0 p-3">
              <div class="row">
                <div class="col-md-8 d-flex align-items-center">
                  <h6 class="mb-0">Profile Information</h6>
                </div>
                <div class="col-md-4 text-end">
                  <a href="javascript:;">
                    <i
                      class="fas fa-user-edit text-secondary text-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Edit Profile"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="card-body">
              <p class="text-sm">Hi, {{ getFullName() }}</p>
              <ul class="list-group">
                <li class="list-group-item border-0 ps-0 text-sm">
                  <strong class="text-dark">Mobile:</strong> &nbsp;
                  {{ getMobilePhone() }}
                </li>
                <li class="list-group-item border-0 ps-0 text-sm">
                  <strong class="text-dark">Email:</strong> &nbsp;
                  {{ getEmailAddress() }}
                </li>
              </ul>
              <hr class="horizontal gray-light my-4" />
            </div>
            <div class="card-body pt-0 mt-0">
              <div class="row">
                <div class="col-md-8 d-flex align-items-center">
                  <h6 class="mb-0">Merchant Information</h6>
                </div>
                <div class="row">
                  <VueMultiselect
                    :multiple="true"
                    v-model="partnerId"
                    :options="partnerOptions"
                    :taggable="false"
                    label="name"
                    track-by="name"
                    placeholder="Select Merchant"
                  >
                  </VueMultiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <GenerateMBayarVA :partnerdata="partnerId"></GenerateMBayarVA>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import UserService from "@/services/user.service";
import GenerateMBayarVA from "@/views/GenerateMBayarVA";
import ProfileChangePassword from "@/views/ProfileChangePassword";
import NavigationTop from "@/components/NavigationTop";
export default {
  name: "UserProfile",
  components: {
    GenerateMBayarVA,
    ProfileChangePassword,
    NavigationTop,
  },
  data: () => ({
    isLoading2FA: false,
    status: undefined,
    message: "",
    loading: false,
    balDisbursment: 0,
    partnerId: "",
    balBiller: 0,
    walletLoading: false,
    timer: undefined,
    gaValidationFlag: 0,
    dataJsonInfo: [],
    img_profile: require("@/assets/img/profile-toppay.png"),
    partnerOptions: [
      { id: 1, text: "Test" },
      { id: 2, text: "Test2" },
    ],
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    getGAValidationFlag() {
      return UserService.getUser().ga_validation_flag;
    },
    getPartnerList() {
      this.loading = true;
      DashboardService.getLinkedPartner(this.dataJsonInfo).then((response) => {
        if (response.data.code == 1) {
          this.partnerOptions = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getFullName() {
      return UserService.getUserFullName();
    },
    getEmailAddress() {
      return UserService.getUserEmail();
    },
    getMobilePhone() {
      return UserService.getMobilePhone();
    },
    async getUserBalance() {
      let balance = await UserService.getUserBalance();
      this.balBiller = balance.data.data.Biller;
      this.balDisbursment = balance.data.data.Disbursment;
    },
    handleGASwitchChange(e) {
      const flagging2FAStatus = e.target.checked ? 1 : 0;
      this.isLoading2FA = true;
      DashboardService.update2FA({
        ga_validation_flag: flagging2FAStatus,
      })
        .then((response) => {
          const user = UserService.getUserWithCode();
          if (response.code == 1) {
            this.status = response.status;
            this.message = response.message;
            const updateUser = {
              ...response,
              data: {
                ...user.data,
                ga_validation_flag: flagging2FAStatus,
              },
            };
            localStorage.setItem("user", JSON.stringify(updateUser));
          } else {
            this.status = response.status;
            this.message = response.message;
            this.gaValidationFlag = e.target.checked ? 0 : 1;
          }
        })
        .catch((error) => {
          this.status = error.response.status;
          this.message = error.response.data.message;
        })
        .finally(() => {
          this.isLoading2FA = false;
        });
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  beforeMount() {
    this.getUserBalance();
    this.getPartnerList();
    this.gaValidationFlag = this.getGAValidationFlag();
    //this.timer = setInterval(this.getUserBalance, 10000);
  },
  beforeUnmount() {
    //clearInterval(this.timer);
  },
};
</script>

<style scoped></style>
