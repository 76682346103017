<template>
  <main class="main-content">
    <div class="container pl-2 pr-2">
      <div class="alert alert-danger" role="alert" v-if="isNotFound">
        Transaction not found
      </div>
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
        v-if="loading"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="center" v-else>
        <div class="card mt-4">
          <div class="row mb-4">
            <div class="col-12 text-center">
              <p class="text-title"><strong>Waiting For Payment</strong></p>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-3 col-sm-12 col-12 text-center">
              <p for="exampleInputEmail1" class="form-control-label text-label">
                Destination Bank
              </p>
              <img
                :src="getBankImages"
                class="img-fluid float-center ml-2"
                :class="getBankClassName"
              />
            </div>
            <div class="col-lg-3 col-sm-12 col-12 text-center">
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  class="form-control-label text-label"
                  >Payment Reference No.</label
                >
                <input
                  type="text"
                  class="form-control-plaintext text-center"
                  readonly
                  :value="form.transaction_ref_no"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-12 col-12 text-center">
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  class="form-control-label text-label"
                  >Payment Code / Destination Account</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-plaintext text-center text-value"
                    readonly
                    :value="form.va_number"
                  />
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        class="fa pr-2"
                        v-bind="props"
                        :class="clipboardClass1"
                        @click="copyToClipBoard(form.va_number, 1)"
                      ></v-icon>
                    </template>
                    <span>{{ tooltipContent1 }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-12 col-12 text-center">
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  class="form-control-label text-label"
                  >Amount to be paid</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-plaintext text-center text-value"
                    readonly
                    :value="formattingNumber(form.amount)"
                  />
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        class="fa pr-2"
                        v-bind="props"
                        :class="clipboardClass2"
                        @click="copyToClipBoard(form.amount, 2)"
                      ></v-icon>
                    </template>
                    <span>{{ tooltipContent2 }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <p class="text-label">
                Purchase/payment will <strong>automatically</strong> be
                cancelled if fund transfer is not received before
                <strong>{{ getExpDate }}</strong> at
                <strong>{{ getExpTime }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card-body">
                <div class="card mb-3 bg-gray">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <p class="text-title"><strong>How to Pay</strong></p>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="content-pay text-label">
                          <ol>
                            <li>
                              Please make sure the
                              <strong>Merchant Name</strong> and
                              <strong>Amount to be paid</strong> are correct.
                            </li>
                            <li>
                              Please make a payment/transfer to the
                              <strong>Destination Bank</strong> with the
                              <strong>Payment Code/Destination Account</strong>
                              as displayed as above.
                            </li>
                            <li>
                              Payment/transfer can be performed via ATM, Mobile
                              Banking or Internet Banking using the realtime
                              online payment/transfer feature.
                            </li>
                            <li>Please keep the receipt for your record.</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 text-center">
              <button
                type="button"
                class="btn btn-outline-primary"
                @click="goToMerchant"
              >
                Back to merchant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber } from "@/services/number-format";
import moment from "moment";

export default {
  name: "PaymentVaPage",
  created() {
    document.title = "Checkout Page";
  },
  mounted() {
    if (this.$route.query.transId == undefined) {
      this.$router.go(-1);
    } else {
      this.fetchData();
      this.repeater = setInterval(() => {
        this.fetchData();
        if (this.status == 1) {
          this.$router.push(
            "/payment-success?isSuccess=1&redirectUrl=" + this.responseUrl
          );
        } else if (this.status > 1) {
          this.$router.push(
            "/payment-success?isSuccess=0&redirectUrl=" + this.responseUrl
          );
        }
      }, 10000);
    }
  },
  beforeUnmount() {
    clearInterval(this.repeater);
  },
  components: {},
  computed: {
    getRemainingTime: function () {
      let time = this.remainingTime / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (secondes < 10) {
        secondes = "0" + secondes;
      }
      return minutes + ":" + secondes;
    },
    getExpDate: function () {
      return moment(this.form.vaDateExp).format("DD MMMM YYYY");
    },
    getExpTime: function () {
      return moment(this.form.vaDateExp).format("HH:mm");
    },
    getBankImages: function () {
      let bankName = this.form.bank_code.toLowerCase().trim();
      return bankName == null || bankName == ""
        ? ""
        : this.bankNames[bankName]["src"];
    },
    getBankClassName: function () {
      let bankName = this.form.bank_code.toLowerCase();
      return bankName == null || bankName == ""
        ? ""
        : this.bankNames[bankName]["className"];
    },
  },
  methods: {
    formattingNumber(val) {
      return "Rp." + formatNumber(val, 0) + ",-";
    },
    copyToClipBoard(textToCopy, sequence) {
      navigator.clipboard.writeText(textToCopy);
      this["tooltipContent" + sequence] = "Copied";
      this["clipBoardClass" + sequence] = "fa-check";

      setTimeout(() => {
        this["tooltipContent" + sequence] = "Copy to clipboard";
        this["clipBoardClass" + sequence] = "fa-clipboard";
      }, 1500);
    },
    fetchData() {
      console.log("fetc");
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_VA_DETAIL + this.$route.query.transId,
        this.searchParam,
        false
      ).then((response) => {
        let resp = response.data.data;
        if (resp && resp.length > 0) {
          this.form.va_number = resp[0].va_number;
          this.form.transaction_ref_no = resp[0].transaction_ref_no;
          this.form.bank_code = resp[0].bank_code;
          this.form.amount = resp[0].amount;
          this.form.vaDateExp = resp[0].qr_expiry_timestamp;
          this.qrString = resp[0].qr_string;
          let dateExp = new Date(resp[0].qr_expiry_timestamp);
          let dateNow = new Date(resp[0].serverTime.date);
          this.isExpired = dateNow > dateExp;
          var diff = Math.abs(dateExp - dateNow);
          this.remainingTime = diff / 1000;
          this.responseUrl = resp[0].response_url;
          this.status = resp[0].paid_status;
        } else {
          this.isNotFound = true;
        }
        this.loading = false;
        //if (this.status == 1) {
        //    this.$router.push("/payment-success?isSuccess=1&status=2&redirectUrl=" + this.responseUrl);
        // } else if (this.isExpired) {
        //     this.$router.push("/payment-failed?status=2&redirectUrl=" + this.responseUrl);
        // } else if (this.remainingTime <= 0) {
        //     this.$router.push("/payment-success?isSuccess=0&status=1&redirectUrl=" + this.responseUrl);
        // }
      });
    },
    goToMerchant() {
      this.$router.push("/payment-redirect?redirectUrl=" + this.responseUrl);
    },
  },
  data: () => ({
    bankNames: {
      bca: { src: "/assets/img/banks/bca.png", className: "default-bank" },
      bni: { src: "/assets/img/banks/bni.png", className: "default-bank" },
      permata: {
        src: "/assets/img/banks/permata.png",
        className: "default-bank",
      },
      mandiri: {
        src: "/assets/img/banks/mandiri.png",
        className: "default-bank",
      },
      bri: { src: "/assets/img/banks/bri.png", className: "default-bank" },
      cimb: { src: "/assets/img/banks/cimb.png", className: "cimb-bank" },
      danamon: {
        src: "/assets/img/banks/danamon.png",
        className: "default-bank",
      },
    },
    loading: false,
    isNotFound: false,
    isExpired: false,
    repeater: null,
    status: false,
    timer: null,
    qrString: "",
    qrSize: 300,
    remainingTime: 0,
    responseUrl: "",
    clipboardClass1: "fa-clipboard",
    tooltipContent1: "Copy to clipboard",
    clipboardClass2: "fa-clipboard",
    tooltipContent2: "Copy to clipboard",
    form: {
      bank_code: "",
      transaction_ref_no: "",
      va_number: "",
      vaDateExp: "",
      amount: 0,
    },
  }),
};
</script>

<style scoped>
.qrvalue-style {
  margin-right: auto;
  margin-left: auto;
}

.text-title {
  font-size: 2.25rem;
  line-height: 1.3;
  font-weight: 500;
  color: #272985;
}
.text-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4rem;
  color: #344767;
}
.text-value {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.4rem;
  color: #344767;
}
.content-pay {
  padding: 1.5rem;
}

.bg-gray {
  background: rgba(0, 0, 0, 0.05);
}
.default-bank {
  height: 75px;
  width: 75px;
}
.cimb-bank {
  height: 75px;
  width: 170px;
}
</style>
