<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card mb-12">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col">
                                <h6>Data {{currentRouteName}}</h6>
                            </div>
                            <div class="col"> 
                                <router-link to="/manage-information/add" class="btn btn-primary float-right margin-0 pd-10">
                                    <span><i class="fas fa-plus-circle"></i></span>
                                    Add
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <!-- <div class="card-body p-3">
                            <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_name=true :f_paymentid=true :f_category=true></TableFilter>
                        </div> -->
                        <div class="card-body p-3">
                            <EasyDataTable
                                show-index
                                buttons-pagination
                                alternating
                                v-model:server-options="serverOptions"
                                :server-items-length="numberOfPages"
                                :loading="loading"
                                :headers="headers"
                                :items="infos"
                                :search-field="searchField"
                                :search-value="searchValue"
                                header-text-direction="center"
                            >
                            <template #item-is_active="{is_active}">
                                <div class="bg-success text-center" v-if="is_active==1">
                                <span class="badge badge-success">Active</span>
                                </div>
                                <div class="bg-danger text-center" v-else>
                                <span class="badge badge-danger">Not Active</span>
                                </div>
                            </template>
                            <template #item-view="item">
                                <router-link :to="'/manage-information/'+item.id+'/edit'" class="btn btn-success my-3">
                                  Edit
                                </router-link>
                            </template>
                            <template #loading>
                                <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                            </template>
                            </EasyDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import RequestService from "@/services/request.service";
  import e2payApis from "@/services/e2pay-apis";
  // import TableFilter from "@/views/TableFilter";
  export default {
    name: "TableManageInformation",
    components: {
      // TableFilter,
    },
    computed:{
      currentRouteName() {
        return this.$route.name;
      }
    },
    methods:{
      searchSubmit(dataParam){
        this.searchParam = dataParam;
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData();
      },
      viewProduct(item){
        this.editProduct = true;
        this.productItemData = item;
      },
      fetchData(){
        this.loading = true;
        this.searchParam.serverOptions = this.serverOptions;
        RequestService.postRequest(e2payApis.GET_ALL_INFORMATION, this.searchParam).then(
            (response)=>{
              this.infos = response.data.data.messages;
              this.numberOfPages = response.data.data.count;
              this.loading = false;
            }
        );
      }
    },
    data:()=>({
      imageLoading: require('@/assets/img/cube_loading.gif'),
      infos: [],
      content:"",
      numberOfPages : 0,
      searchField:'refNo',
      searchValue:'',
      loading: false,
      productItemData:[],
      editProduct: false,
      headers: [
        { text: "Start Date", value: "start_date" , sortable: true},
        { text: "End Date", value: "end_date" , sortable: true},
        { text: "Text", value: "message_text" , sortable: true},
        { text: "Type", value: "classinfo" , sortable: true},
        { text: "Menu", value: "menu" , sortable: true},
        { text: "Active", value: "is_active" , sortable: true},
        { text: "View", value: "view" , sortable: false},
      ],
      searchParam:{},
      serverOptions:{
        page: 1,
        rowsPerPage: 25,
        sortBy:'',
        sortType:'',
      }
    }),
    mounted() {
      this.fetchData();
    },
    watch:{
      serverOptions:{
        handler(){
          this.searchParam.serverOptions = this.serverOptions;
          this.fetchData(this.searchParam);
        },
        deep: true
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>