<template>
  <div class="card card-frame">
    <div class="card-header">
      <h6 class="font-weight-bolder mb-0">Manual Disbursement Notification</h6>
    </div>
    <div class="card-body">
      <div class="row" v-if="isError">
        <div class="col">
          <div
            class="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <span class="alert-text text-white"
              ><strong>Error!</strong> {{ errorMsg }}</span
            >
            <button
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <form>
        <div class="row">
          <div class="col-12">
            <h6
              class="bg-success text-white"
              v-if="this.form.errMsg == null || this.form.errMsg == ''"
            >
              Disbursement Success !
            </h6>
            <h6 class="bg-danger text-white" v-else>
              Disbursement Failed ! {{ this.form.errMsg }}
            </h6>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Transaction ID</label
              >
              <div class="col-7">
                <!-- <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="form.paymentId.product_name"> -->
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.transId"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Reference ID</label
              >
              <div class="col-7">
                <!-- <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="form.paymentId.product_name"> -->
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.payId"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Transaction Time</label
              >
              <div class="col-7">
                <!-- <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="form.paymentId.product_name"> -->
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.processedAt"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Bank Name</label
              >
              <div class="col-7">
                <!-- <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="form.paymentId.product_name"> -->
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.bankName"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Bank Account No./Mobile Number</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.customerId"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Bank Account Name</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.bankAccountName"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Amount</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="getTotal"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Status</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.status"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 row">
              <label
                for="staticEmail"
                class="col-4 col-form-label font-weight-normal"
                >Remarks</label
              >
              <div class="col-7">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-bold"
                  :value="form.remarks"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-2">
        <div class="col-12 col-md-12">
          <button
            @click="close()"
            class="btn btn-success mr-2"
            :disabled="loading"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber } from "@/services/number-format";
import useSimulatorFormStore from "@/store/simulator.form";

export default {
  name: "SimulatorPayinFormPage3",
  mounted() {
    if (this.simulatorForm.form == null) {
      this.$router.go(-1);
    } else {
      console.log(this.simulatorForm, "simulatorForm");
      this.form = this.simulatorForm.form;
    }
  },
  methods: {
    startCountDown() {
      this.timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          clearInterval(this.timer);
          // this.sound.play()
          // this.reset()
        }
      }, 1000);
    },
    close() {
      this.$router.push({ name: "Manual Disbursement Page" });
    },
  },
  data: () => ({
    v$: useValidate(),
    simulatorForm: useSimulatorFormStore(),
    loading: false,
    timer: "",
    countDown: 30,
    isError: false,
    errorMsg: "",
    status: "",
    form: {
      isSuccess: 1,
      paymentId: {
        product_name: "",
      },
    },
  }),
  computed: {
    getTotal: function () {
      return "Rp." + formatNumber(this.form.totalAmount, 0) + ",-";
    },
  },
  watch: {
    "form.MerchantCode": function (newVal) {
      RequestService.postRequest(e2payApis.GET_LINKED_CHANNEL, {
        type: "payin",
        partnerId: newVal.code,
      }).then((response) => {
        if (response.data.code == 1) {
          this.refChannels = response.data.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.isError = true;
          this.errorMsg = response.data.message;
        }
      });
    },
  },
};
</script>
