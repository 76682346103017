<template>
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <h6>Data {{ currentRouteName }}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter
                  :f_submit="searchSubmit"
                  :f_loading="loading"
                  :f_username="true"
                  :f_activity="true"
                  :f_status_success="true"
                ></TableFilter>
              </div>
              <div class="card-body p-3">
                <EasyDataTable
                  show-index
                  buttons-pagination
                  alternating
                  v-model:server-options="serverOptions"
                  :server-items-length="numberOfPages"
                  :loading="loading"
                  :headers="headers"
                  :items="activities"
                >
                  <template #item-success_status="{ success_status }">
                    <span
                      v-if="success_status == 'SUCCESS'"
                      class="badge bg-success text-white text-bold"
                      >SUCCESS</span
                    >
                    <span
                      v-if="success_status == 'FAILED'"
                      class="badge bg-danger text-white text-bold"
                      >FAILED</span
                    >
                  </template>
                  <template #loading>
                    <img
                      :src="imageLoading"
                      style="width: 100px; height: 80px"
                    />
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
export default {
  name: "TableRequestLog",
  components: {
    NavigationTop,
    TableFilter,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    searchSubmit(dataParam) {
      this.searchParam = {
        ...dataParam,
        filter_success: Number(dataParam.filter_success),
      };
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getActivityLogData(this.searchParam).then((response) => {
        this.activities = response.data.data.data;
        this.numberOfPages = response.data.data.dataCount;
        this.loading = false;
      });
    },
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    activities: [],
    content: "",
    numberOfPages: 0,
    searchField: "refNo",
    searchValue: "",
    loading: false,
    headers: [
      { text: "Activity Datetime", value: "created_at", sortable: true },
      { text: "Activity Type", value: "activity", sortable: true },
      { text: "Username", value: "username", sortable: true },
      { text: "Merchant", value: "ip_address", sortable: true },
      { text: "Type", value: "endpoint", sortable: true },
      { text: "Remarks", value: "remarks", sortable: true },
      { text: "Status", value: "success_status", sortable: true },
    ],
    searchParam: {},
    serverOptions: {
      page: 1,
      rowsPerPage: 25,
      sortBy: "",
      sortType: "",
    },
  }),
  mounted() {
    this.fetchData();
  },
  watch: {
    serverOptions: {
      handler() {
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
