<template>
    <div class="modal fade" ref="price-modal" :id="modalId" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Price Form</h5>
                    <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label class="form-control-label">Product Name</label>
                            <input type="text" class="form-control" :value="paymentName" disabled="disabled" placeholder="Product Name">
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Partner ID</label>
                            <VueMultiselect
                                :multiple="false"
                                v-model="dataJsonInfo.form_p_partner"
                                :options="partnerOptions"
                                :taggable="false"
                                label="name"
                                track-by="code"
                                placeholder="Pick Partner"
                            ></VueMultiselect>
                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_p_partner.$error">{{ v$.dataJsonInfo.form_p_partner.$errors[0].$message }}</span>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Minimum Trx Amount (0 for no minimum)</label>
                            <input type="number" class="form-control" v-model="dataJsonInfo.form_minamount" placeholder="0">
                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_minamount.$error">{{ v$.dataJsonInfo.form_minamount.$errors[0].$message }}</span>
                        </div>
                        <div class="form-group">
                          <label class="form-control-label">Is Active</label>
                          <div class="form-check form-switch">
                            <input class="form-check-input bg-success" type="checkbox" v-model="dataJsonInfo.form_p_active" 
                              true-value="true" false-value="false">
                          </div>
                        </div>
                        <!-- style="width: 18rem;" -->
                        <div class="card" >
                            <div class="card-body">
                                <h5 class="card-title">Topup</h5>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-control-label">Fix Fee</label>
                                            <input type="text" class="form-control" v-model="dataJsonInfo.toppay.ffee" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.toppay.ffee.$error">{{ v$.dataJsonInfo.toppay.ffee.$errors[0].$message }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Security Fee</label>
                                            <input type="text" class="form-control" v-model="dataJsonInfo.toppay.sfee" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.toppay.sfee.$error">{{ v$.dataJsonInfo.toppay.sfee.$errors[0].$message }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-control-label">Model</label>
                                            <VueMultiselect
                                                :multiple="false"
                                                v-model="dataJsonInfo.toppay.fmodel"
                                                :options="modelOptions"
                                                :taggable="false"
                                                label="text"
                                                track-by="id"
                                                placeholder="Pick Model"
                                            ></VueMultiselect>
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.toppay.fmodel.$error">{{ v$.dataJsonInfo.toppay.fmodel.$errors[0].$message }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Model</label>
                                            <VueMultiselect
                                                :multiple="false"
                                                v-model="dataJsonInfo.toppay.smodel"
                                                :options="modelOptions"
                                                :taggable="false"
                                                label="text"
                                                track-by="id"
                                                placeholder="Pick Model"
                                            ></VueMultiselect>
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.toppay.smodel.$error">{{ v$.dataJsonInfo.toppay.smodel.$errors[0].$message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body">
                                <h5 class="card-title">Referral</h5>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-control-label">Fix Fee</label>
                                            <input type="text" class="form-control" v-model="dataJsonInfo.referral.ffee" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.referral.ffee.$error">{{ v$.dataJsonInfo.referral.ffee.$errors[0].$message }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Security Fee</label>
                                            <input type="text" class="form-control" v-model="dataJsonInfo.referral.sfee" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.referral.sfee.$error">{{ v$.dataJsonInfo.referral.sfee.$errors[0].$message }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-control-label">Model</label>
                                            <VueMultiselect
                                                :multiple="false"
                                                v-model="dataJsonInfo.referral.fmodel"
                                                :options="modelOptions"
                                                :taggable="false"
                                                label="text"
                                                track-by="id"
                                                placeholder="Pick Model"
                                            ></VueMultiselect>
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.referral.fmodel.$error">{{ v$.dataJsonInfo.referral.fmodel.$errors[0].$message }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Model</label>
                                            <VueMultiselect
                                                :multiple="false"
                                                v-model="dataJsonInfo.referral.smodel"
                                                :options="modelOptions"
                                                :taggable="false"
                                                label="text"
                                                track-by="id"
                                                placeholder="Pick Model"
                                            ></VueMultiselect>
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.referral.smodel.$error">{{ v$.dataJsonInfo.referral.smodel.$errors[0].$message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" >
                            <div class="card-body">
                                <h5 class="card-title">Supplier</h5>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-control-label">Fix Fee</label>
                                            <input type="text" class="form-control" v-model="dataJsonInfo.supplier.ffee" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.supplier.ffee.$error">{{ v$.dataJsonInfo.supplier.ffee.$errors[0].$message }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Security Fee</label>
                                            <input type="text" class="form-control" v-model="dataJsonInfo.supplier.sfee" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.supplier.sfee.$error">{{ v$.dataJsonInfo.supplier.sfee.$errors[0].$message }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="form-control-label">Model</label>
                                            <VueMultiselect
                                                :multiple="false"
                                                v-model="dataJsonInfo.supplier.fmodel"
                                                :options="modelOptions"
                                                :taggable="false"
                                                label="text"
                                                track-by="id"
                                                placeholder="Pick Model"
                                            ></VueMultiselect>
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.supplier.fmodel.$error">{{ v$.dataJsonInfo.supplier.fmodel.$errors[0].$message }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Model</label>
                                            <VueMultiselect
                                                :multiple="false"
                                                v-model="dataJsonInfo.supplier.smodel"
                                                :options="modelOptions"
                                                :taggable="false"
                                                label="text"
                                                track-by="id"
                                                placeholder="Pick Model"
                                            ></VueMultiselect>
                                            <span class="invalid-feedback" v-if="v$.dataJsonInfo.supplier.smodel.$error">{{ v$.dataJsonInfo.supplier.smodel.$errors[0].$message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-secondary" id="closeModal" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn bg-gradient-primary" @click="onSubmit">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {formatNumber, getFormattedNumber} from "@/services/number-format"
import useValidate from '@vuelidate/core'
import {required, numeric} from '@vuelidate/validators';
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
// import VueDatePicker from '@vuepic/vue-datepicker';

function initialState() {
    return {
        form_p_payment:'',
        form_p_partner_name: '',
        form_p_partner:'',
        form_p_active: true,
        form_minamount:0,
        toppay: {
            sfee: 0,
            smodel: {id:'F',text:'Fixed Amount'},
            smodel_text:'',
            fmodel_text:'',
            ffee: 0,
            fmodel: {id:'F',text:'Fixed Amount'},
        },
        referral: {
            sfee: 0,
            smodel: {id:'F',text:'Fixed Amount'},
            smodel_text:'',
            fmodel_text:'',
            ffee: 0,
            fmodel: {id:'F',text:'Fixed Amount'},
        },
        supplier: {
            sfee: 0,
            smodel: {id:'F',text:'Fixed Amount'},
            smodel_text:'',
            fmodel_text:'',
            ffee: 0,
            fmodel: {id:'F',text:'Fixed Amount'},
        },
        item:[]
    };
}

export default {
    name: "PriceModalForm",
    props:{
        modalId: String,
        paymentId: Number,
        paymentName: String,
        price: Object
    },
    components: {
        // VueDatePicker
    },
    mounted() {
        this.getPartnerList();
        this.getPaymentModels();
        this.dataJsonInfo.form_p_payment = this.paymentId;
    },
    data:()=>({
        v$: useValidate(),
        loading: false,
        partnerOptions: [],
        modelOptions:[],
        dataJsonInfo: initialState(),
    }),
    validations() {
        return {
            dataJsonInfo: {
                form_p_payment:{required},
                form_p_partner:{required},
                form_minamount:{required, numeric},
                toppay: {
                    sfee: {required},
                    smodel: {required},
                    ffee: {required},
                    fmodel: {required},
                },
                referral: {
                    sfee: {required},
                    smodel: {required},
                    ffee: {required},
                    fmodel: {required},
                },
                supplier: {
                    sfee: {required},
                    smodel: {required},
                    ffee: {required},
                    fmodel: {required},
                },
                // form_p_description:{minLength:minLength(5)},
            }
        }
    },
    emits:['submit'],
    methods: {
        clearData() {
            return this.dataJsonInfo = initialState();
        },
        setterNumeric(val) {
            return formatNumber(val);
        },
        getterNumeric(val) {
            return getFormattedNumber(val);
        },
        getPartnerList(){
            this.loading = true;
            RequestService.postRequest(e2payApis.GET_PARTNER_LIST, {}).then(
                (response)=>{
                    if(response.data.code==1){
                    this.partnerOptions = response.data.data;
                    this.loading = false;
                    }else{
                    this.loading = false;
                    }
                }
            )
        },
        getPaymentModels(){
            this.loading = true;
            RequestService.postRequest(e2payApis.GET_PAYMENT_MODELS, {}).then(
                (response)=>{
                    if(response.data.code==1){
                    this.modelOptions = response.data.data;
                    this.loading = false;
                    }else{
                    this.loading = false;
                    }
                }
            )
        },
        onSubmit() {
            this.dataJsonInfo.form_p_payment = this.paymentId;
            this.dataJsonInfo.form_p_partner_name = this.dataJsonInfo.form_p_partner.name;
            this.dataJsonInfo.form_p_partner = this.dataJsonInfo.form_p_partner.code;
            this.dataJsonInfo.toppay.smodel = this.dataJsonInfo.toppay.smodel.id;
            this.dataJsonInfo.toppay.smodel_text = this.dataJsonInfo.toppay.smodel.text;
            this.dataJsonInfo.toppay.fmodel = this.dataJsonInfo.toppay.fmodel.id;
            this.dataJsonInfo.toppay.fmodel_text = this.dataJsonInfo.toppay.fmodel.text;
            this.dataJsonInfo.referral.smodel = this.dataJsonInfo.referral.smodel.id;
            this.dataJsonInfo.referral.smodel_text = this.dataJsonInfo.referral.smodel.text;
            this.dataJsonInfo.referral.fmodel = this.dataJsonInfo.referral.fmodel.id;
            this.dataJsonInfo.referral.fmodel_text = this.dataJsonInfo.referral.fmodel.text;
            this.dataJsonInfo.supplier.smodel = this.dataJsonInfo.supplier.smodel.id;
            this.dataJsonInfo.supplier.smodel_text = this.dataJsonInfo.supplier.smodel.text;
            this.dataJsonInfo.supplier.fmodel = this.dataJsonInfo.supplier.fmodel.id;
            this.dataJsonInfo.supplier.fmodel_text = this.dataJsonInfo.supplier.fmodel.text;
            this.$emit('submit', this.dataJsonInfo);
            this.clearData();
            document.getElementById('closeModal').click();
        }
    },
    watch: {
        price: function(newVal) {
            this.dataJsonInfo = newVal;
            this.dataJsonInfo.form_p_partner = this.partnerOptions.find(option => option.code === this.dataJsonInfo.form_p_partner);
            this.dataJsonInfo.toppay.fmodel = this.modelOptions.find(option => option.id === this.dataJsonInfo.toppay.fmodel);
            this.dataJsonInfo.toppay.smodel = this.modelOptions.find(option => option.id === this.dataJsonInfo.toppay.smodel);
            this.dataJsonInfo.referral.fmodel = this.modelOptions.find(option => option.id === this.dataJsonInfo.referral.fmodel);
            this.dataJsonInfo.referral.smodel = this.modelOptions.find(option => option.id === this.dataJsonInfo.referral.smodel);
            this.dataJsonInfo.supplier.fmodel = this.modelOptions.find(option => option.id === this.dataJsonInfo.supplier.fmodel);
            this.dataJsonInfo.supplier.smodel = this.modelOptions.find(option => option.id === this.dataJsonInfo.supplier.smodel);
        }
    }
}
</script>