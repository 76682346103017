<template>
  <div class="card bg-gray-300">
    <div class="card-body p-3">
      <div v-if="loading" style="height: 182px">
        <div
          class="text-center d-flex align-items-center justify-content-center h-100"
        >
          <img
            width="10"
            class="img-fluid"
            :src="imageLoading"
            style="width: 80px; height: 50px"
          />
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-8">
          <div class="numbers">
            <p class="text-sm mb-0 text-capitalize font-weight-bold">
              {{ item.issuerName }}
            </p>
            <h6 class="font-weight-bold mb-0">
              {{ item.balance }}
            </h6>
          </div>
        </div>
        <div class="col-4" v-if="loading === false">
          <v-img
            v-if="item.issuerName == 'DVI'"
            alt="logo"
            :src="require('@/assets/img/dvi-logo.png')"
            style="width: 100%; height: 150px"
          />
          <v-img
            v-if="item.issuerName == 'Nextrans'"
            alt="logo"
            :src="require('@/assets/img/nextrans-logo.jpg')"
            style="width: 100%; height: 150px"
          />
          <v-img
            v-if="item.issuerName == 'LinkQu'"
            alt="logo"
            :src="require('@/assets/img/linkqu-logo.png')"
            style="width: 100%; height: 150px"
          />
          <v-img
            v-if="item.issuerName == 'Payhere'"
            alt="logo"
            :src="require('@/assets/img/payhere-logo.png')"
            style="width: 100%; height: 150px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "IssuerBalanceItem",
  props: {
    item: undefined,
    classname: String,
    loading: Boolean,
  },
  mounted() {
    console.log(this.loading);
    console.log(this.item, "item");
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    color: ref("yellow"),
  }),
};
</script>

<style scoped></style>
