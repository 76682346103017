<template>
  <v-dialog persistent v-model="dialog" height="auto">
    <div class="card card-frame">
      <div v-if="loading" style="height: 182px">
        <div
          class="text-center d-flex align-items-center justify-content-center h-100"
        >
          <img
            width="10"
            class="img-fluid"
            :src="imageLoading"
            style="width: 80px; height: 50px"
          />
        </div>
      </div>
      <div v-else>
        <div class="card-header">
          <span class="text-h5">Detail PayOut Transaksi</span>
        </div>
        <div class="card-body">
          <!-- <form>
                   <div class="row">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Trx ID</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="data.trx_id">
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Requested Datetime</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="data.requested_at">
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Requested By</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="data.requested_user">
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Merchant</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.partner_name">
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Type</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.overdraft_type_desc">
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Amount</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.amount_in_string">
                               </div>
                           </div>
                       </div>
                       <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                           <div class="row">
                               <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Status</label>
                               <div class="col-7">
                                   <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.status_desc">
                               </div>
                           </div>
                       </div>
                   </div>
               </form> -->
          <div class="row mt-2 text-center">
            <div class="col-12 col-md-12">
              <button
                @click="this.$emit('closeDialog')"
                class="btn btn-block btn-info mb-0 mr-0"
                :disabled="loading"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalPayinDetailsTrx",
  props: {
    modalId: String,
    openDialog: Boolean,
    data: Object,
    loading: Boolean,
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    dialog: false,
  }),
  emits: ["closeDialog"],
  watch: {
    openDialog: function (newVal) {
      this.dialog = newVal;
    },
    data: function (newVal) {
      console.log("newval", newVal);
    },
  },
};
</script>
