<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <IssuerBalanceRightContent></IssuerBalanceRightContent>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import IssuerBalanceRightContent from "@/views/IssuerBalanceRightContent";

export default {
  name: "RoutePageIssuerBalance",
  components: {
    DashboardLeftMenu,
    IssuerBalanceRightContent,
  },
};
</script>

<style scoped></style>
