import { defineStore } from 'pinia'

const useSimulatorFormStore = defineStore('simulatorForm', {
  state: () => {
    return { form: {} }
  },
  actions: {
    setForm(params) {
      this.form = params;
    },
  },
})

export default useSimulatorFormStore;