<template>
  <div class="container-fluid py-4">
    <!-- <PageActionTopupRequest :collapsed="editProduct" :product="productItemData"></PageActionTopupRequest> -->
    <div class="row">
      <div class="col-12">
        <div class="card mb-12">
          <div class="card-header pb-0">
            <div class="row">
              <div class="col">
                <h6>Data {{currentRouteName}}</h6>
              </div>
              <!-- <div class="col"> 
                <router-link to="/topuprequest/add" class="btn btn-primary float-right margin-0 pd-10">
                    <span><i class="fas fa-plus-circle"></i></span>
                    Add
                </router-link>
              </div> -->
            </div>
          </div>
          <div class="card">
            <div class="card-body p-3">
              <TableFilter f_action='payin' :f_loading=loading :f_submit="searchSubmit" :f_download=true :f_partner_code=true :f_date_range=true ></TableFilter>
            </div>
            <div class="card-body p-3">
              <a :href="filedownload" v-if="filedownload" class="btn btn-behance mr-2" type="button"><i class="fa fa-solid fa-file-excel"></i> Dowload</a>
              <EasyDataTable
                  show-index
                  buttons-pagination
                  alternating
                  v-model:server-options="serverOptions"
                  :server-items-length="numberOfPages"
                  :loading="loading"
                  :headers="headers"
                  :items="transactions"
                  :search-field="searchField"
                  :search-value="searchValue"
              >
                <!-- <template #expand="item">
                  <div class="card" v-if="item.topup_status=='A'">
                    <div class="card-body">
                      <div class="row"> 
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Request Wallet</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_wallet">
                            </div>
                          </div>
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Approval Wallet Remarks</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_wallet_remarks">
                            </div>
                          </div>
                        </div>
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-3 col-form-label">Approval Wallet</label>
                            <div class="col-8">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_wallet_approved_amt">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row"> 
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Request Disbursement</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_disbusrment">
                            </div>
                          </div>
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Approval Disbursement Remarks</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_disbusrment_remarks">
                            </div>
                          </div>
                        </div>
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-3 col-form-label">Approval Disbursement</label>
                            <div class="col-8">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_disbusrment_approved_amt">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row"> 
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Request Local Bank</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_local_bank">
                            </div>
                          </div>
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Approval Local Bank Remarks</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_local_bank_remarks">
                            </div>
                          </div>
                        </div>
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-3 col-form-label">Approval Local Bank</label>
                            <div class="col-8">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_local_bank_approved_amt">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row"> 
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Request Crypto</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_crypto">
                            </div>
                          </div>
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Approval Crypto Remarks</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_crypto_remarks">
                            </div>
                          </div>
                        </div>
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-3 col-form-label">Approval Crypto</label>
                            <div class="col-8">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_crypto_approved_amt">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row"> 
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Request Overdraft</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_overdraft">
                            </div>
                          </div>
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-4 col-form-label">Approval Overdraft Remarks</label>
                            <div class="col-7">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_overdraft_remarks">
                            </div>
                          </div>
                        </div>
                        <div class="col-6"> 
                          <div class="mb-3 row" >
                            <label for="staticEmail" class="col-3 col-form-label">Approval Overdraft</label>
                            <div class="col-8">
                              <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_overdraft_approved_amt">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mb-3 row" >
                        <label for="staticEmail" class="col-2 col-form-label">Overall Remarks</label>
                        <div class="col-8">
                          <input type="text" readonly class="form-control-plaintext" id="staticEmail" :value="item.topup_remarks">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="padding: 15px" v-else>
                    {{item.product_name}} settlement {{item.settlement_time}} days
                  </div>
                </template> -->
                <template #item-topup_status="item">
                  <span v-if="item.topup_status=='R'" class="badge bg-info text-white text-bold">{{item.topup_status}}</span>
                  <span v-else-if="item.topup_status=='A'" class="badge bg-success text-white text-bold">{{item.topup_status}}</span>
                  <span v-else class="badge bg-warning text-white text-bold">{{item.topup_status}}</span>
                </template>
                <template #item-amount="{amount}">
                  <span class="text-bold">{{amount}}</span>
                </template>
                <template #item-topup_id="item">
                  <router-link :to="{
                    name:'topuprequest-view',
                    params: {id:item.topup_id, item:JSON.stringify(item)}
                  }" class="btn btn-success my-3">
                    view
                  </router-link>
                </template>
                <template #loading>
                  <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import TableFilter from "@/views/TableFilter";
// import PageActionTopupRequest from "@/views/PageActionTopupRequest";
export default {
  name: "TableTopupRequest",
  components:{
    TableFilter
    // PageActionTopupRequest
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    downloadSubmit(){

    },
    doAction(item){
      this.editProduct = true;
      this.productItemData = item;
    },
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;

      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getTopupRequestData(this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.filedownload = response.data.data.downloadLink || '';
            if(!this.filedownload){
              this.downloadSubmit();
            }
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    filedownload:'',
    transactions: [],
    content:"",
    numberOfPages : 0,
    searchField:'refNo',
    editProduct: false,
    searchValue:'',
    loading: false,
    headers: [
      { text: "Merchant Code", value: "partner_code" , sortable: true},
      { text: "Merchant Name", value: "partner_name" , sortable: true},
      { text: "Requested Date", value: "topup_date" , sortable: true},
      { text: "Requested By", value: "topup_by" , sortable: true},
      { text: "Status", value: "topup_status" , sortable: true},
      { text: "Aproval Date", value: "topup_approval_date" , sortable: true},
      { text: "Approval By", value: "topup_approval_by" , sortable: true},
      { text: "Action", value: "topup_id" , sortable: true}
    ],
    searchParam:{},
    productItemData:[],
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>