<template>
    <div class="card card-frame">
        <div class="card-header">
            Simulator Payout
        </div>
        <div class="card-body"> 
            <div class="row" v-if="isError"> 
                <div class="col"> 
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                        <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
                        <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Payment ID</label>
                        <VueMultiselect
                            :multiple="false"
                            v-model="form.PaymentId"
                            :options="refChannels"
                            label="product_name"
                            track-by="payment_id"
                            placeholder="Pick channel"
                        >
                        </VueMultiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Merchant Code</label>
                        <VueMultiselect
                            :multiple="false"
                            v-model="form.MerchantCode"
                            :options="refMerchants"
                            label="partner_name"
                            track-by="partner_code"
                            placeholder="Pick Merchant"
                        >
                        </VueMultiselect>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Service</label>
                      <input type="text" class="form-control" v-model="form.Service">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Reference No</label>
                      <input type="text" class="form-control" v-model="form.RefNo">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Pay ID</label>
                      <input type="text" class="form-control" v-model="form.PayId" >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Customer Phone</label>
                      <input type="text" class="form-control" v-model="form.CustomerId" >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Amount</label>
                      <input type="number" class="form-control" v-model="form.Amount" >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Backend URL</label>
                      <input type="text" class="form-control" v-model="form.BackendURL" >
                    </div>
                  </div>
                </div>
            </form>
            <div class="row mt-2">
                <div class="col-12 col-md-12"> 
                    <button @click="simulate()" class="btn btn-success mr-2" :disabled="loading" >Simulate</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";

export default {
  name: "SimulatorPayoutForm",
  mounted() {
    this.fetchData();
  },
  methods:{
    fetchData(){
        this.loading = true;
        RequestService.postRequest(e2payApis.GET_CHANNEL_LIST, {type: 'payout'}).then(
            (response)=>{
                if(response.data.code==1){
                    this.refChannels = response.data.data;
                    this.loading = false;
                }else{
                    this.loading = false;
                    this.isError = true;
                    this.errorMsg = response.data.message;
                }
            }
        );
        RequestService.postRequest(e2payApis.GET_PARTNER_INFO, {type: 'payout'}).then(
            (response)=>{
                if(response.data.code==1){
                    this.refMerchants = response.data.data.data;
                    this.loading = false;
                }else{
                    this.loading = false;
                    this.isError = true;
                    this.errorMsg = response.data.message;
                }
            }
        );
    },
    simulate() {
        let paymentidTemp = this.form.PaymentId;
        let merchantCodeTemp = this.form.MerchantCode;
        this.form.PaymentId = this.form.PaymentId.payment_id;
        this.form.MerchantCode = this.form.MerchantCode.partner_code;
        RequestService.postRequest(e2payApis.GENERATE_SIGNATURE_PAYOUT, {
            PaymentId: this.form.PaymentId,
            MerchantCode: this.form.MerchantCode,
            RefNo: this.form.RefNo,
            Service: this.form.Service,
            CustomerId: this.form.CustomerId
        }).then(
          (response)=>{
            if(response.data.code=="00"){
              this.loading = false;
              this.form.Signature = response.data.data;
              RequestService.postRequest(e2payApis.POST_SIMULATOR_PAYOUT, this.form).then(
                (response)=>{
                  if(response.data.code=="00"){
                    this.loading = false;
                    console.log('resp', response.data.data);
                    this.$router.push({ name: 'Simulator Payout Result', params: {result: JSON.stringify(response.data.data)}});
                  }else{
                    this.form.PaymentId = paymentidTemp;
                    this.form.MerchantCode = merchantCodeTemp; 
                    this.loading = false;
                    this.isError = true;
                    this.errorMsg = response.data.message;
                  }
                }
              )
            }else{
                this.form.PaymentId = paymentidTemp;
                this.form.MerchantCode = merchantCodeTemp; 
                this.loading = false;
                this.isError = true;
                this.errorMsg = response.data.message;
            }
          }
        )
    }
  },
  data:()=>({
        v$: useValidate(),
        loading: false,
        registerMessage:'',
        isError: false,
        errorMsg: '',
        status: '',
        refChannels: [],
        refMerchants: [],
        refCurrencies: ["IDR"],
        refLanguages: ["ENG"],
        form: {
            PaymentId: 0,
            MerchantCode: '',
            Service: '',
            RefNo: '',
            PayId: '',
            CustomerId: '',
            Amount: '',
            BackendURL: '',
            Signature: '0r2iur4saIcECrvV0t/8cqO0cYo='
        }
    })
}
</script>