<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_partner_name=true :f_partner_code=true :f_amount="true" :f_date_range=true :f_refno=true></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <ShapeInfo title="Total Topup Balance"></ShapeInfo>
                  <ShapeInfo title="Current Balance"></ShapeInfo>
                </div>
                <EasyDataTable
                    show-index
                    buttons-pagination
                    alternating
                    v-model:server-options="serverOptions"
                    :server-items-length="numberOfPages"
                    :loading="loading"
                    :headers="headers"
                    :items="balancehistory"
                    :search-field="searchField"
                    :search-value="searchValue"
                >
                  <template #expand="item">
                    <div style="padding: 15px">
                      {{item.trx_id}} {{item.trxtype}} {{item.trxDate}} {{item.amount}}
                    </div>
                  </template>
                  <template #item-trxtype="{trxtype}">
                    <div class="bg-success text-center" v-if="trxtype=='PAYMENT'">
                      <span class="badge badge-success">{{trxtype}}</span>
                    </div>
                    <div class="bg-warning text-center" v-else>
                      <span class="badge badge-warning">{{trxtype}}</span>
                    </div>
                  </template>
                  <template #item-amount="{amount,trxtype}">
                    <span v-if="trxtype=='PAYMENT'" class="text-bold">-{{amount}}</span>
                    <span v-if="trxtype=='REVERSAL'" class="text-bold">+{{amount}}</span>
                  </template>
                  <template #loading>
                    <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import ShapeInfo  from "@/components/ShapeInfo";
export default {
  name: "TableBalanceHistoryWallet",
  components:{
    NavigationTop,
    TableFilter,
    ShapeInfo
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.ballType = 'wallet';
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.ballType = 'wallet';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getBalanceHistory(this.searchParam).then(
          (response)=>{
            this.balancehistory = response.data.data.data;
            console.log(this.balancehistory);
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    numberOfPages : 0,
    balancehistory: [],
    content:"",
    searchField:undefined,
    searchValue:'',
    loading: false,
    headers: [
      { text: "Trx Id", value: "trx_id" },
      { text: "Date", value: "trxDate" },
      { text: "Partner Code", value: "partner_code" },
      { text: "Partner Name", value: "partner_name" },
      { text: "Prev Balance", value: "prev_balance" , sortable: true},
      { text: "Amount", value: "amount" },
      { text: "Current Balance", value: "curr_balance" , sortable: true},
      { text: "Type", value: "trxtype" },
      { text: "Merchant Name", value: "partner_name" },
      { text: "RefNo", value: "payid" },
      { text: "Info", value: "infodesc" },
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>