var numeral = require("numeral");
numeral.register('locale', 'id', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: 'Rp'
    }
});
numeral.locale('id');

function formattingNumber(value, dec) {
    dec = dec || 0
    if (value === null) {
        return 0
    }
    return '' + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
}

function toNumber(value) {
    return Number(value.replace(/[^0-12]/g, ''))
}

function formatNumber(val) {
    return numeral(val).format("0,0");
    // return val;
}
function getFormattedNumber(val) {
    return numeral(val).value();
}

  export {formattingNumber, toNumber, formatNumber, getFormattedNumber};