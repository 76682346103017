<template>
     <div class="card card-frame">
        <div class="card-header">
            <button class="btn btn-icon btn-3 btn-outline-primary mt-2" @click="$router.go(-1)">
                <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
            </button>
            Manage Information 
        </div>
        <div class="card-body"> 
            <div class="row" v-if="isError"> 
                <div class="col"> 
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
                    <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                </div>
            </div>
            <form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Date Start</label>
                            <VueDatePicker valueType="format" v-model="form.start_date" format="yyyy-MM-dd HH:mm:ss" enable-seconds required />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Date End</label>
                            <VueDatePicker valueType="format" v-model="form.end_date" format="yyyy-MM-dd HH:mm:ss" enable-seconds required />
                        </div>
                    </div>
                </div>
                <div class="row">
                  <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Link to PG</label>
                        
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">PG Config</label>
                        <textarea rows="3" class="form-control" v-model="form.form_p_pg_config" 
                            placeholder="PG Config">
                        </textarea>
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label">Text</label>
                      <input type="text" class="form-control" :class="{'is-invalid' : v$.form.message_text.$error}" v-model="form.message_text" placeholder="Text">
                      <span class="invalid-feedback" v-if="v$.form.message_text.$error">{{ v$.form.message_text.$errors[0].$message }}</span>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Display Time</label>
                        <input type="number" class="form-control" :class="{'is-invalid' : v$.form.display_time.$error}" v-model="form.display_time" placeholder="Display Times" aria-label="Display Times" aria-describedby="display-times">
                        <span class="invalid-feedback" v-if="v$.form.display_time.$error">{{ v$.form.display_time.$errors[0].$message }}</span>
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Class Info</label>
                        <VueMultiselect
                            :multiple="false"
                            v-model="form.class_info"
                            :options="refClassInfos"
                            label="name"
                            track-by="id"
                            placeholder="Pick Class Info"
                        >
                        </VueMultiselect>
                        <span class="invalid-feedback" v-if="v$.form.class_info.$error">{{ v$.form.class_info.$errors[0].$message }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Menu</label>
                        <input type="text" class="form-control" :class="{'is-invalid' : v$.form.menu.$error}" v-model="form.menu" placeholder="Menu" aria-label="Menu" aria-describedby="menu">
                        <span class="invalid-feedback" v-if="v$.form.menu.$error">{{ v$.form.menu.$errors[0].$message }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                        <label class="form-control-label">Is Active</label>
                        <div class="form-check form-switch mt-2">
                          <input class="form-check-input bg-success" type="checkbox" v-model="form.is_active" 
                            true-value="1" false-value="0">
                        </div>
                    </div>
                  </div>
                </div>
            </form>
            <div class="row mt-2" v-if="status != 'A'">
                <div class="col-12 col-md-12"> 
                    <button @click="submit()" class="btn btn-success mr-2" :disabled="loading_partner" >Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import VueMultiselect from 'vue-multiselect'
import useValidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "ManageInformationForm",
  props:{
    // paramModel: ,
    // collapsed: Boolean,
  },
  components: {
    // VueMultiselect,
    VueDatePicker,
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    fetchData(){
      if (this.$route.params.id !== undefined) {
        this.loading = true;
        RequestService.postRequest(e2payApis.GET_INFORMATION, {id: this.$route.params.id}).then(
            (response)=>{
              let items = response.data.data.messages;
              if (items.length > 0) {
                let item = items[0];
                this.form.id = item.id;
                this.form.start_date = item.start_date;
                this.form.end_date = item.end_date;
                this.form.message_text = item.message_text;
                // this.form.message_greeting = item.message_greeting;
                const result = this.refClassInfos.find((classInfo) => {
                  return classInfo.id === item.classinfo;
                });
                this.form.class_info = result;
                // this.form.display_time = item.display_time;
                this.form.menu = item.menu;
                this.form.is_active = item.is_active;
              }
            }
        );
      }
    },
    submit() {
      this.v$.form.$validate();
      if(!this.v$.form.$error){
        let tempClassInfo = this.form.class_info;
        this.form.class_info = this.form.class_info.id;
        RequestService.postRequest(this.$route.params.id !== undefined ? e2payApis.PUT_INFORMATION: 
          e2payApis.POST_INFORMATION, this.form).then(
            (response)=>{
              this.loading = false;
              if(response.data.code==1){
                this.$router.go(-1);
              }else{
                this.isError = true;
                this.errorMsg = response.data.message;
                this.form.class_info = tempClassInfo;
              }
            }
        )
      }
    }
  },
  data:()=>({
    v$: useValidate(),
    loading_partner: false,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    partner_info_collapsed: false,
    registerMessage:'',
    isDisabledCryptoModal: true,
    isError: false,
    errorMsg: '',
    status: '',
    refClassInfos:[
      {
        "id":"alert-info text-white",
        "name":"INFO"
      },
      {
        "id":"alert-success text-white",
        "name":"SUCCESS"
      },
      {
        "id":"alert-warning text-white",
        "name":"WARNING"
      },
      {
        "id":"alert-danger text-white",
        "name":"DANGER"
      }
    ],
    form: {
      id: '',
      start_date: '',
      end_date: '',
      message_text: '',
      // message_greeting: '',
      // display_time: '',
      class_info: '',
      menu: '',
      is_active: 1,
    },
  }),
  validations() {
    return {
      form: {
        start_date:{required},
        end_date:{required},
        message_text:{required},
        // message_greeting:{required},
        // display_time:{required,numeric},
        class_info:{required},
        menu:{required}
      }
    }
  },
}


</script>