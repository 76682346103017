<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <PageEditProductPrice :collapsed="editProduct" :product="productItemData"></PageEditProductPrice>
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_channel=true :f_feemodel=true :f_partner_code=true :f_partner_name=true></TableFilter>
              </div>
              <div class="card-body p-3">
                *Displayed number uses 5-digit decimal with a dot separator
                <EasyDataTable
                    show-index
                    buttons-pagination
                    alternating
                    v-model:server-options="serverOptions"
                    :server-items-length="numberOfPages"
                    :loading="loading"
                    :headers="headers"
                    :items="transactions"
                    :search-field="searchField"
                    :search-value="searchValue"
                >
                  <template #item-is_active="{is_active}">
                    <div class="bg-success text-center" v-if="is_active==1">
                      <span class="badge badge-success">Active</span>
                    </div>
                    <div class="bg-gray-600 text-center" v-else>
                      <span class="badge badge-warning">Not Active</span>
                    </div>
                  </template>
                  <template #item-view="item">
                    <div class="bg-success text-center">
                      <button @click="viewProductPrice(item)"  class="btn-sm btn-success">View</button>
                    </div>
                  </template>
                  <template #loading>
                    <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import PageEditProductPrice from "@/views/PageEditProductPrice";
export default {
  name: "TableProductPrice",
  components: {
    NavigationTop,
    TableFilter,
    PageEditProductPrice
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    viewProductPrice(item){
      this.editProduct = true;
      this.productItemData = item;
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getProductsPriceListData(this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    transactions: [],
    content:"",
    numberOfPages : 0,
    searchField:'refNo',
    searchValue:'',
    loading: false,
    productItemData:[],
    editProduct: false,
    headers: [
      { text: "Payment Id", value: "payment_id" , sortable: true},
      { text: "Product Name", value: "product_name" , sortable: true},
      { text: "Partner Code", value: "partner_code" , sortable: true},
      { text: "Partner Name", value: "partner_name" , sortable: true},
      { text: "Min Amount", value: "minimum_trx" , sortable: true},
      { text: "FFee", value: "product_fee" , sortable: true},
      { text: "FModel", value: "feemodel" , sortable: true},
      { text: "SFee", value: "product_sfee" , sortable: true},
      { text: "SModel", value: "product_sfeemodel" , sortable: true},
      { text: "ResellerFFee", value: "referral_ffee" , sortable: true},
      { text: "ResellerFModel", value: "referral_ffeemodel" , sortable: true},
      { text: "ResellerSFee", value: "referral_sfee" , sortable: true},
      { text: "ResellerSModel", value: "referral_sfeemodel" , sortable: true},
      { text: "SupplierFFee", value: "supplier_ffee" , sortable: true},
      { text: "SupplierFModel", value: "supplier_ffeemodel" , sortable: true},
      { text: "SupplierSFee", value: "supplier_sfee" , sortable: true},
      { text: "SupplierSModel", value: "supplier_ffeemodel" , sortable: true},
      { text: "Active", value: "is_active" , sortable: true},
      { text: "View", value: "view" , sortable: false},
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>