<template>
  <div class="row">
    <div class="col-12">
      <div class="card" >
        <div class="card-header bg-gray-200">Add/Edit Product Price <button @click="doViewFormEditProductPrice"><i :class="partnerInfoIcon"></i></button> </div>
        <div class="card-body bg-gray-100" v-if="partner_info_collapsed">
          <div class="row mb-3">
            <div class="col-md-12">
              <div class="col-md-12 mb-3">
                <label class="form-label">Partner Id</label>
                <VueMultiselect v-show="!loadingPartner"
                                :multiple="false"
                                v-model="dataJsonInfo.form_p_partner"
                                :options="partnerOptions"
                                :taggable="false"
                                label="name"
                                track-by="code"
                                placeholder="Pick Partner"
                >
                </VueMultiselect>
                <span v-if="v$.dataJsonInfo.form_p_partner.$error">{{ v$.dataJsonInfo.form_p_partner.$errors[0].$message }}</span>
              </div>
              <div class="col-md-12 mb-3">
                <label class="form-label">Payment Id</label>
                <VueMultiselect v-show="!loadingPayment"
                                :multiple="false"
                                v-model="dataJsonInfo.form_p_payment"
                                :options="channelOptions"
                                :taggable="false"
                                label="name"
                                track-by="code"
                                placeholder="Pick Payment Channel"
                >
                </VueMultiselect>
                <span v-if="v$.dataJsonInfo.form_p_payment.$error">{{ v$.dataJsonInfo.form_p_payment.$errors[0].$message }}</span>
              </div>
              <div class="col-md-12 mb-3">
                <div class="input-group">
                  <span class="input-group-text bg-red-lighten-2">Minimum Trx Amount (0 for no minimum)</span>
                  <input type="number" v-model="dataJsonInfo.form_minamount" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.form_minamount.$error">{{ v$.dataJsonInfo.form_minamount.$errors[0].$message }}</span>
              </div>
            </div>
          </div>
          <div class="form-control mb-3">
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text bg-success">Toppay Fix Fee</span>
                  <input type="text" v-model="dataJsonInfo.toppay.ffee" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.toppay.ffee.$error">{{ v$.dataJsonInfo.toppay.ffee.$errors[0].$message }}</span>
              </div>
              <div class="col-md-6">
                <VueMultiselect
                    :multiple="false"
                    v-model="dataJsonInfo.toppay.fmodel"
                    :options="modelOptions"
                    :taggable="false"
                    label="text"
                    track-by="id"
                    placeholder="Pick Model"
                ></VueMultiselect>
                <span v-if="v$.dataJsonInfo.toppay.fmodel.$error">{{ v$.dataJsonInfo.toppay.fmodel.$errors[0].$message }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text bg-success">Toppay Security Fee</span>
                  <input type="text" v-model="dataJsonInfo.toppay.sfee" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.toppay.sfee.$error">{{ v$.dataJsonInfo.toppay.sfee.$errors[0].$message }}</span>
              </div>
              <div class="col-md-6">
                <VueMultiselect
                    :multiple="false"
                    v-model="dataJsonInfo.toppay.smodel"
                    :options="modelOptions"
                    :taggable="false"
                    label="text"
                    track-by="id"
                    placeholder="Pick Model"
                ></VueMultiselect>
                <span v-if="v$.dataJsonInfo.toppay.smodel.$error">{{ v$.dataJsonInfo.toppay.smodel.$errors[0].$message }}</span>
              </div>
            </div>
          </div>
          <div class="form-control mb-3">
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text bg-warning">Referral Fix Fee</span>
                  <input type="text" v-model="dataJsonInfo.referral.ffee" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.referral.ffee.$error">{{ v$.dataJsonInfo.referral.ffee.$errors[0].$message }}</span>
              </div>
              <div class="col-md-6">
                <VueMultiselect
                    :multiple="false"
                    v-model="dataJsonInfo.referral.fmodel"
                    :options="modelOptions"
                    :taggable="false"
                    label="text"
                    track-by="id"
                    placeholder="Pick Model"
                ></VueMultiselect>
                <span v-if="v$.dataJsonInfo.referral.fmodel.$error">{{ v$.dataJsonInfo.referral.fmodel.$errors[0].$message }}</span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text bg-warning">Referral Secutity Fee</span>
                  <input type="text" v-model="dataJsonInfo.referral.sfee" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.referral.sfee.$error">{{ v$.dataJsonInfo.referral.sfee.$errors[0].$message }}</span>
              </div>
              <div class="col-md-6">
                <VueMultiselect
                    :multiple="false"
                    v-model="dataJsonInfo.referral.smodel"
                    :options="modelOptions"
                    :taggable="false"
                    label="text"
                    track-by="id"
                    placeholder="Pick Model"
                ></VueMultiselect>
                <span v-if="v$.dataJsonInfo.referral.smodel.$error">{{ v$.dataJsonInfo.referral.smodel.$errors[0].$message }}</span>
              </div>
            </div>
          </div>
          <div class="form-control mb-3">
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text bg-blue-grey">Supplier Fix Fee</span>
                  <input type="text" v-model="dataJsonInfo.supplier.ffee" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.supplier.ffee.$error">{{ v$.dataJsonInfo.supplier.ffee.$errors[0].$message }}</span>
              </div>
              <div class="col-md-6">
                <div class="input-group">
                  <VueMultiselect
                      :multiple="false"
                      v-model="dataJsonInfo.supplier.fmodel"
                      :options="modelOptions"
                      :taggable="false"
                      label="text"
                      track-by="id"
                      placeholder="Pick Model"
                  ></VueMultiselect>
                  <span v-if="v$.dataJsonInfo.supplier.fmodel.$error">{{ v$.dataJsonInfo.supplier.fmodel.$errors[0].$message }}</span>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text bg-blue-grey">Supplier Security Fee</span>
                  <input type="text" v-model="dataJsonInfo.supplier.sfee" aria-label="First name"
                         class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                </div>
                <span v-if="v$.dataJsonInfo.supplier.sfee.$error">{{ v$.dataJsonInfo.supplier.sfee.$errors[0].$message }}</span>
              </div>
              <div class="col-md-6">
                <div class="input-group">
                  <VueMultiselect
                      :multiple="false"
                      v-model="dataJsonInfo.supplier.smodel"
                      :options="modelOptions"
                      :taggable="false"
                      label="text"
                      track-by="id"
                      placeholder="Pick Model"
                  ></VueMultiselect>
                  <span v-if="v$.dataJsonInfo.supplier.smodel.$error">{{ v$.dataJsonInfo.supplier.smodel.$errors[0].$message }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-control mb-3">
            <div class="form-group d-flex align-items-center justify-content-between">
              <span class="text-sm text-bold">Activate Price?</span>
              <div class="form-check form-switch ms-3">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault30" v-model="dataJsonInfo.form_p_active" >
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <span class="d-block text-danger">* Please be carefull when editing this setting</span>
            <div class="alert alert-light" role="alert">
              <ul>
                <li>Use dot for procentage, example 0.01 (1%)</li>
                <li>Minimum transaction amount, use 0 if no minimum amount</li>
                <li>If no existing product price,  data will be added</li>
                <li>Otherwise for existing product price will be updated</li>
              </ul>
            </div>
          </div>
          <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
          <span class="d-block" text-black>{{registerMessage}}</span>
          <div class="mb-3">
            <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
            <button v-show="showAddProductPrice" @click="doProductPriceAdd" class="btn btn-success mr-2" :disabled="loading_partner" type="button" >Add</button>
            <button v-show="!showAddProductPrice" @click="doProductPriceEdit" class="btn btn-warning mr-2" :disabled="loading_partner" type="button" >Edit</button>
            <button @click="doClearInfo" class="btn btn-info" :disabled="loading_partner" type="button" >Clear</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
export default {
  name: "PageEditProductPrice",
  props:{
    product: undefined,
    collapsed: Boolean,
  },
  computed:{
    showAddProductPrice(){
      if(this.dataJsonInfo.item.length==0){
        return true;
      }else{
        return false;
      }
    }
  },
  methods:{
    doProductPriceEdit(){
      this.loading_partner = true;
      DashboardService.addProductPrice(this.dataJsonInfo).then(
          (response)=>{
            console.log(response);
            if(response.data.code==1){
              this.registerMessage = response.data.message;
              this.registerError = response.data.error;
              this.loading_partner = false;
            }else{
              this.registerMessage = response.data.message;
              this.registerError = response.data.error;
              this.loading_partner = false;
            }
          }
      )
    },
    doProductPriceAdd(){
      this.v$.dataJsonInfo.$validate();
      if(!this.v$.dataJsonInfo.$error) {
        this.loading = true;
        DashboardService.addProductPrice(this.dataJsonInfo).then(
            (response)=>{
              console.log(response);
              if(response.data.code==1){
                this.registerMessage = response.data.message;
                this.registerError = response.data.error;
                this.loading_partner = false;
              }else{
                this.registerMessage = response.data.message;
                this.registerError = response.data.error;
                this.loading_partner = false;
              }
            }
        )
      }else{
        this.loading = false;
        this.registerMessage = this.v$.dataJsonInfo.$errors[0].message;
      }
    },
    doClearInfo(){
      this.dataJsonInfo.form_minamount = 0;
      this.dataJsonInfo.form_p_payment='';
      this.dataJsonInfo.form_p_partner='';
      this.dataJsonInfo.toppay.sfee =  0;
      this.dataJsonInfo.toppay.smodel = '';
      this.dataJsonInfo.toppay.ffee =  0;
      this.dataJsonInfo.toppay.fmodel= 'F';
      this.dataJsonInfo.referral.sfee = 0;
      this.dataJsonInfo.referral.smodel =  '';
      this.dataJsonInfo.referral.ffee =  0;
      this.dataJsonInfo.referral.fmodel =  '';
      this.dataJsonInfo.supplier.sfee =  0;
      this.dataJsonInfo.supplier.smodel =  '';
      this.dataJsonInfo.supplier.ffee = 0;
      this.dataJsonInfo.supplier.fmodel = '';
      this.dataJsonInfo.form_p_active='';
    },
    doViewFormEditProductPrice() {
      this.partner_info_collapsed = !this.partner_info_collapsed;
      if(this.partner_info_collapsed){
        this.partnerInfoIcon = 'fa-solid fa-chevron-up';
      }else{
        this.partnerInfoIcon = 'fa-solid fa-chevron-down';
      }
    },
    getFeeModel(){
      this.loadingFeeModel = true;
      DashboardService.getFeeModel(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.modelOptions = response.data.data;
              this.loadingFeeModel = false;
            }else{
              this.loadingFeeModel = false;
            }
          }
      )
    },
    getPartnerList(){
      this.loadingPartner = true;
      DashboardService.getPartnerList(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.partnerOptions = response.data.data;
              this.loadingPartner = false;
            }else{
              this.loadingPartner = false;
            }
          }
      )
    },
    getPaymentListCode(){
      this.loadingPayment = true;
      DashboardService.getPaymentListCode(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.channelOptions = response.data.data;
              this.loadingPayment = false;
            }else{
              this.loadingPayment = false;
            }
          }
      )
    },
  },
  data:()=>({
    v$: useValidate(),
    loadingPartner: true,
    loadingPayment: true,
    loadingFeeModel: true,
    partner_info_collapsed: false,
    loading: false,
    registerMessage:'',
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    partnerOptions:[],
    channelOptions:[],
    modelOptions:[],
    dataJsonInfo: {
      form_p_payment:'',
      form_p_partner:'',
      form_p_active:'',
      form_minamount:0,
      toppay: {
        sfee: 0,
        smodel: {id:'F',text:'Fixed Amount'},
        ffee: 0,
        fmodel: {id:'F',text:'Fixed Amount'},
      },
      referral: {
        sfee: 0,
        smodel: {id:'F',text:'Fixed Amount'},
        ffee: 0,
        fmodel: {id:'F',text:'Fixed Amount'},
      },
      supplier: {
        sfee: 0,
        smodel: {id:'F',text:'Fixed Amount'},
        ffee: 0,
        fmodel: {id:'F',text:'Fixed Amount'},
      },
      item:[]
    },
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_payment:{required},
        form_p_partner:{required},
        form_minamount:{required},
        toppay: {
          sfee: {required},
          smodel: {required},
          ffee: {required},
          fmodel: {required},
        },
        referral: {
          sfee: {required},
          smodel: {required},
          ffee: {required},
          fmodel: {required},
        },
        supplier: {
          sfee: {required},
          smodel: {required},
          ffee: {required},
          fmodel: {required},
        },
      }
    }
  },
  beforeMount() {
    this.getPartnerList();
    this.getPaymentListCode();
    this.getFeeModel();
  },
  watch:{
    collapsed:{
      immediate: true,
      deep:true,
      handler(){
        this.partner_info_collapsed = this.collapsed;
        if(this.partner_info_collapsed){
          this.partnerInfoIcon = 'fa-solid fa-chevron-up';
        }else{
          this.partnerInfoIcon = 'fa-solid fa-chevron-down';
        }
      }
    },
    product:{
      immediate: true,
      deep:true,
      handler(){
        this.dataJsonInfo.item = this.product;
        this.dataJsonInfo.form_p_payment = this.product.payment_list;
        this.dataJsonInfo.form_p_partner = this.product.partner_list;
        this.dataJsonInfo.form_p_active = this.product.is_active==1?true:false;

        this.dataJsonInfo.form_minamount = this.product.minimum_trx;

        this.dataJsonInfo.toppay.ffee = this.product.product_fee;
        this.dataJsonInfo.toppay.fmodel = this.product.feemodel_list;
        this.dataJsonInfo.toppay.sfee = this.product.product_sfee;
        this.dataJsonInfo.toppay.smodel = this.product.product_sfeemodel_list;

        this.dataJsonInfo.referral.sfee = this.product.referral_sfee;
        this.dataJsonInfo.referral.smodel = this.product.referral_sfeemodel_list;
        this.dataJsonInfo.referral.ffee = this.product.referral_ffee;
        this.dataJsonInfo.referral.fmodel = this.product.referral_ffeemodel_list;

        this.dataJsonInfo.supplier.sfee = this.product.supplier_sfee;
        this.dataJsonInfo.supplier.smodel = this.product.supplier_sfeemodel_list;
        this.dataJsonInfo.supplier.ffee = this.product.supplier_ffee;
        this.dataJsonInfo.supplier.fmodel = this.product.supplier_ffeemodel_list;

      }
    }
  }
}
</script>

<style scoped>

</style>