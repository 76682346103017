<template>
  <main class="main-content mt-0">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div class="card card-plain mt-8">
                <div class="card-header pb-0 text-left bg-transparent">
                  <h3 class="font-weight-bolder text-info text-gradient">
                    Please verify your account
                  </h3>
                  <div
                    v-if="getUser.lastlogin == null || getUser.lastlogin == 0"
                    class="flex flex-col gap-y-6"
                  >
                    <p className="text-sm font-medium text-[#777677]">
                      Please scan the QR code below with your authentication app
                      to receive a one-time password (OTP).
                    </p>
                    <div
                      className="mx-auto size-[294px] rounded-sm bg-[#EEF9FA] text-center"
                    >
                      <img
                        alt="QR Code"
                        className="h-full w-full object-cover"
                        :src="getUser.qr_code_url"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <p class="text-sm font-medium text-[#777677]">
                    Please enter the six-digit code sent to your authentication
                    app.
                  </p>

                  <VOtpInput
                    :is-disabled="loading"
                    ref="otpInput"
                    input-classes="otp-input"
                    :conditionalClass="[
                      'one',
                      'two',
                      'three',
                      'four',
                      'five',
                      'six',
                    ]"
                    separator=""
                    inputType="numeric"
                    :num-inputs="6"
                    v-model:value="bindValue"
                    :should-auto-focus="true"
                    :should-focus-order="true"
                    @on-complete="handleOnComplete"
                    :placeholder="['*', '*', '*', '*', '*', '*']"
                  />

                  <div class="mt-3">
                    <div
                      v-if="status == 422"
                      class="alert alert-danger"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <div
                      v-if="status == 200"
                      class="alert alert-success"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                  </div>
                  <div class="text-center">
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <button
                      class="btn bg-gradient-info w-100 mt-4 mb-0"
                      :disabled="loading"
                      @click="handleSubmitOTP"
                    >
                      Verify & Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8"
              >
                <div
                  class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  style="
                    background-image: url('../assets/img/curved-images/curved6.jpg');
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import * as yup from "yup";
import userService from "@/services/user.service";
import { ref } from "vue";
import VOtpInput from "vue3-otp-input";

const otpInput = ref(null);

export default {
  name: "VerifyOTPScreen",
  components: {
    VOtpInput,
  },
  data() {
    return {
      loading: false,
      message: "",
      status: undefined,
      pin: "",
    };
  },
  computed: {
    getUser() {
      return userService.getUser();
    },
  },
  methods: {
    getEmailAddress() {
      return userService.getUserEmail();
    },
    handleSubmitOTP() {
      this.loading = true;
      const user = this.getEmailAddress();
      this.$store
        .dispatch("auth/verifyOTP", { email: user, validation_code: this.pin })
        .then(
          (data) => {
            if (data.code == 1) {
              this.loading = false;
              this.message = data.message;
              this.status = data.status;
              setTimeout(() => {
                this.$router.push("/dashboard");
              }, 1000);
            } else {
              this.loading = false;
              this.message = data.message;
              this.status = data.status;
            }
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.status = error.response.status;
          }
        );
    },
    handleOnComplete(value) {
      this.pin = value;
    },
    fillInput(value) {
      console.log(value);
      otpInput.value?.fillInput(value);
    },
  },
};
</script>

<style>
.otp-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  /* margin: 0 10px; */
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d2d6da;
  text-align: center;
}

.otp-input.is-complete {
  background-color: #f2f2f2;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: #d2d6da;
}
</style>
