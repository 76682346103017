<template>
  <main class="main-content mt-0">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div class="card card-plain mt-8">
                <div class="card-header pb-0 text-left bg-transparent">
                  <h3 class="font-weight-bolder text-info text-gradient">
                    {{ this.$store.state.globvar.logingreeting }}
                  </h3>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <Form @submit="handleLogin" :validation-schema="schema">
                    <label>Email</label>
                    <div class="mb-3">
                      <Field
                        name="username"
                        type="text"
                        class="form-control"
                      ></Field>
                      <ErrorMessage
                        name="username"
                        class="error-feedback"
                      ></ErrorMessage>
                    </div>
                    <label>Password</label>
                    <div class="mb-3">
                      <Field
                        name="password"
                        type="password"
                        class="form-control"
                      ></Field>
                      <ErrorMessage
                        name="password"
                        class="error-feedback"
                      ></ErrorMessage>
                    </div>
                    <div
                      v-if="message && [200].includes(status)"
                      class="alert alert-success text-white"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <div
                      v-if="message && [422].includes(status)"
                      class="alert alert-danger text-white"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <div class="text-center">
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span>
                      <button
                        class="btn bg-gradient-info w-100 mt-4 mb-0"
                        :disabled="loading"
                      >
                        Sign in
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8"
              >
                <div
                  class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  style="
                    background-image: url('../assets/img/curved-images/curved6.jpg');
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "LoginScreen",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required"),
      password: yup.string().required("Password is required"),
    });
    return {
      loading: false,
      message: "",
      status: undefined,
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        (data) => {
          if (data.code == 1 && data.status == 200) {
            this.loading = false;
            this.message = data.message;
            this.status = data.status;

            if (data.data.ga_validation_flag == 0) {
              setTimeout(() => {
                this.$router.push("/dashboard");
              }, 1000);
            } else {
              setTimeout(() => {
                this.$router.push("/verify-otp");
              }, 1000);
            }
          } else if (data.code == 1 && data.status == 422) {
            this.loading = false;
            this.message = data.message;
            this.status = data.status;
            setTimeout(() => {
              this.$router.push("/change-password");
            }, 1000);
          } else {
            this.loading = false;
            this.message = data.message;
            this.status = data.status;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.status = error.response.status;
        }
      );
    },
  },
};
</script>

<style scoped></style>
