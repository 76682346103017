import axios from "axios";
import authHeader from "./auth-header";

//const API_URL = 'http://e2pay.project/dashboard/';
//const API_URL = 'https://stg.toppayaccess.com/dashboard/';
const API_URL = process.env.VUE_APP_BASE_URL + "dashboard/";
class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }
  getUserBalance() {
    let dataTrx = {
      parameter: "userbalance",
    };
    return axios.post(API_URL + "getBalance/", dataTrx, {
      headers: authHeader(),
    });
  }
  getUserWithCode() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user;
  }
  getUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.data;
  }
  getUserToken() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.data.token;
  }
  getUserFullName() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.data.fullname;
  }
  getUserEmail() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.data.email;
  }
  getMobilePhone() {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.data.mobilephone;
  }
  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }
  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }
  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
}

export default new UserService();
