<template>
  <main class="main-content mt-0">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
              <div class="card card-plain mt-8">
                <div class="card-header pb-0 text-left bg-transparent">
                  <h3 class="font-weight-bolder text-info text-gradient">
                    Change Password
                  </h3>
                </div>
                <div class="card-body">
                  <Form
                    @submit="handleChangePassword"
                    :validation-schema="schema"
                  >
                    <label>Email</label>
                    <div class="mb-3">
                      <Field
                        name="email"
                        type="email"
                        class="form-control"
                      ></Field>
                      <ErrorMessage
                        name="email"
                        class="error-feedback"
                      ></ErrorMessage>
                    </div>
                    <label>Password</label>
                    <div class="mb-3">
                      <Field
                        name="password"
                        type="password"
                        class="form-control"
                      ></Field>
                      <ErrorMessage
                        name="password"
                        class="error-feedback"
                      ></ErrorMessage>
                    </div>
                    <label>Confirm Password</label>
                    <div class="mb-3">
                      <Field
                        name="confirm_password"
                        type="password"
                        class="form-control"
                      ></Field>
                      <ErrorMessage
                        name="confirm_password"
                        class="error-feedback"
                      ></ErrorMessage>
                    </div>
                    <div
                      v-if="message && [200].includes(status)"
                      class="alert alert-success text-white"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <div
                      v-if="message && ![200].includes(status)"
                      class="alert alert-danger text-white"
                      role="alert"
                    >
                      {{ message }}
                    </div>
                    <div class="text-center">
                      <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm"
                      ></span>
                      <button
                        class="btn bg-gradient-info w-100 mt-4 mb-0"
                        :disabled="loading"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8"
              >
                <div
                  class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  style="
                    background-image: url('../assets/img/curved-images/curved6.jpg');
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "ChangePasswordScreen",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email("Must be a valid email")
        .required("Email is required"),
      password: yup.string().required("Password is required"),
      confirm_password: yup
        .string()
        .required("Confirm Password is required")
        .oneOf([yup.ref("password"), null], "Confirm Password doesn't match"),
    });
    return {
      loading: false,
      message: "",
      status: undefined,
      schema,
    };
  },
  methods: {
    handleChangePassword(user) {
      this.loading = true;
      this.$store.dispatch("auth/forceChangePassword", user).then(
        (data) => {
          if (data.code == 1) {
            this.loading = false;
            this.message = data.message;
            this.status = data.status;
            setTimeout(() => {
              this.$router.push("/login");
            }, 1000);
          } else {
            this.loading = false;
            this.message = data.message;
            this.status = data.status;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped></style>
