<template>
    <DashboardLeftMenu></DashboardLeftMenu>
    <DistributeBalancePage></DistributeBalancePage>
  </template>
  
  <script>
  import DashboardLeftMenu from "@/components/DashboardLeftMenu";
  import DistributeBalancePage from "@/views/DistributeBalancePage";
  export default {
    name: "RoutePageDistributeBalance",
    props:{
  
    },
    components:{
        DistributeBalancePage,
      DashboardLeftMenu,
    }
  }
  </script>
  
  <style scoped>
  
  </style>