<template>
  <main class="main-content">
    <NavigationTop
      :pages="currentRouteName"
      :subpages="currentRouteName"
    ></NavigationTop>
    <v-alert
      v-if="submitmessage"
      type="success"
      title="Submit Balance Succeed"
      :text="submitmessage"
    ></v-alert>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{ currentRouteName }}</h6>
            </div>
          </div>
          <div class="row">
            <div
              class="card card-body blur shadow-blur mx-4 mt-n6 overflow-hidden"
            >
              <div class="row gx-4">
                <div class="col-auto">
                  <div class="avatar avatar-xl position-relative">
                    <img
                      :src="imageIcon"
                      alt="profile_image"
                      class="w-100 border-radius-lg shadow-sm"
                    />
                  </div>
                </div>
                <div class="col-auto my-auto">
                  <div class="h-100">
                    <h5 class="mb-1">
                      {{ getFullName() }}
                    </h5>
                    <p class="mb-0 font-weight-bold text-sm">
                      {{ getEmailAddress() }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 mt-3">
            <div class="row mb-3 justify-center align-center" v-if="!loading">
              <div class="col-md-6">
                <div class="card-header pb-0 p-3">
                  <div class="col-md-4">
                    <VueMultiselect
                      :multiple="false"
                      v-model="partnerId"
                      :options="partnerOptions"
                      :taggable="false"
                      label="name"
                      track-by="name"
                      placeholder="Select Merchant"
                    >
                    </VueMultiselect>
                  </div>
                </div>
                <div class="card-header">
                  <div class="col-md-6">
                    <div class="d-flex flex-column" v-if="partnerData">
                      <h6 class="mb-3 text-sm">
                        Merchant name:<span class="text-uppercase">{{
                          partnerData.partner_name
                        }}</span>
                      </h6>
                      <span class="mb-2 text-xs"
                        >Status:
                        <span class="text-dark font-weight-bold ms-sm-2">{{
                          partnerData.status
                        }}</span></span
                      >
                      <span class="mb-2 text-xs"
                        >Partner Code:
                        <span class="text-dark font-weight-bold ms-sm-2">{{
                          partnerData.partner_code
                        }}</span></span
                      >
                      <span class="mb-2 text-xs"
                        >Secret Key:
                        <span class="text-dark font-weight-bold ms-sm-2">{{
                          secretKey
                        }}</span>
                        <i
                          @click="showSecret"
                          :class="eyeicon"
                          class="cursor-pointer"
                        ></i
                      ></span>
                      <span class="mb-2 text-xs"
                        >Mobile Phone:
                        <span class="text-dark font-weight-bold ms-sm-2">{{
                          partnerData.partner_telp
                        }}</span></span
                      >
                      <span class="mb-2 text-xs"
                        >Address:
                        <span class="text-dark ms-sm-2 font-weight-bold">{{
                          partnerData.partner_address
                        }}</span></span
                      >
                      <span class="mb-2 text-xs"
                        >Email Address:
                        <span class="text-dark ms-sm-2 font-weight-bold">{{
                          partnerData.partner_email
                        }}</span></span
                      >
                      <span class="text-xs" v-if="partnerData.va != '-'"
                        >VA Number:
                        <span class="text-dark ms-sm-2 font-weight-bold">{{
                          partnerData.va
                        }}</span></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="partnerData">
                <div class="card">
                  <div class="card-body p-3">
                    <div class="row">
                      <v-form @submit.prevent>
                        <!-- <v-text-field
                            v-model="formattedWalletAmount"
                            label="Wallet Amount"
                            prefix="IDR"
                            class="autonumeric"
                          ></v-text-field> -->
                        <v-text-field
                          v-model="formattedDisbursementAmount"
                          label="Disbursement Amount"
                          prefix="IDR"
                        ></v-text-field>
                        <v-btn
                          type="submit"
                          block
                          class="mt-2"
                          @click="submitDistribute"
                          >Submit</v-btn
                        >
                      </v-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import NavigationTop from "@/components/NavigationTop";
import UserService from "@/services/user.service";
import DashboardService from "@/services/dashboard.service";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import { formatNumber, getFormattedNumber } from "@/services/number-format";

export default {
  name: "DistributeBalancePage",
  components: {
    NavigationTop,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    formattedWalletAmount: {
      get() {
        return formatNumber(this.request.wallet_amount, 0);
      },
      set(newValue) {
        this.request.wallet_amount = getFormattedNumber(newValue);
      },
    },
    formattedDisbursementAmount: {
      get() {
        return formatNumber(this.request.disbursment_amount, 0);
      },
      set(newValue) {
        this.request.disbursment_amount = getFormattedNumber(newValue);
      },
    },
  },
  methods: {
    getFullName() {
      return UserService.getUserFullName();
    },
    getEmailAddress() {
      return UserService.getUserEmail();
    },
    getPartnerList() {
      this.loading = true;
      RequestService.postRequest(
        e2payApis.GET_LINKED_PARTNER,
        this.dataJsonInfo
      ).then((response) => {
        if (response.data.code == 1) {
          this.partnerOptions = response.data.data;
          let roleId = DashboardService.getUserRoleId();
          if (roleId != 0) {
            this.partnerId = response.data.data[0];
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    getPartnerInfo() {
      this.loading = true;
      DashboardService.getPartnerInfo(this.partnerJson).then((response) => {
        if (response.data.code == 1) {
          this.partnerData = response.data.data.data[0];
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    submitDistribute() {
      this.submitloading = true;
      this.submitmessage = "";
      this.request.partner_id = this.partnerJson.partnerId;
      RequestService.postRequest(
        e2payApis.POST_DISTRIBUTE_BALANCE,
        this.request
      ).then((response) => {
        console.log("response", response);
        if (response.data.code == 1) {
          this.submitloading = false;
          this.submitmessage = response.data.message;
        } else {
          this.submitmessage = response.data.message;
          this.submitloading = false;
        }
      });
    },
    showSecret() {
      this.openSecret = !this.openSecret;
      if (this.openSecret) {
        this.eyeicon = "fa-regular fa-eye-slash";
        this.secretKey = this.partnerData.partner_secret;
      } else {
        this.eyeicon = "fa-regular fa-eye";
        this.secretKey = "********";
      }
    },
  },
  data: () => ({
    loading: false,
    submitloading: false,
    submitmessage: "",
    secretKey: "********",
    openSecret: false,
    eyeicon: "fa-regular fa-eye-slash",
    imageIcon: require("@/assets/img/logo-paybender.png"),
    merchantList: ["T0001", "T0002", "T0003"],
    partnerOptions: [
      { id: 1, text: "Test" },
      { id: 2, text: "Test2" },
    ],
    partnerId: "",
    partnerData: [],
    partnerJson: {
      partnerId: "",
      otpva: "",
    },
    request: {
      partner_id: "",
      wallet_amount: 0,
      disbursment_amount: 0,
    },
  }),
  beforeMount() {
    this.getPartnerList();
  },
  watch: {
    partnerId: {
      immediate: true,
      deep: true,
      handler() {
        this.partnerJson.partnerId = this.partnerId.code;
        this.getPartnerInfo(this.partnerJson);
      },
    },
  },
};
</script>

<style scoped></style>
