<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <SettlementReport></SettlementReport>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import SettlementReport from "@/views/SettlementReport";
export default {
  name: "RouteSettlementReport",
  components: {
    DashboardLeftMenu,
    SettlementReport
  }

}
</script>

<style scoped>

</style>