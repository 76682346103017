import axios from "axios";
import authHeader from "./auth-header";
import router from "../router";
const API_URL = process.env.VUE_APP_BASE_URL;

class RequestService {
  async isStillLogin() {
    const resp = await axios
      .post(API_URL + "dashboard/refreshToken", {}, { headers: authHeader() })
      .then((response) => {
        if (response.data.code == 1) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    if (resp == false) {
      localStorage.removeItem("user");
      router.push("/login");
    }
  }

  postRequest(url, data, mustAuth = true) {
    if (!mustAuth) {
      return axios.post(API_URL + url, data, {});
    }
    if (this.isStillLogin()) {
      return axios.post(API_URL + url, data, { headers: authHeader() });
    }
  }

  sendCallback(url, mustAuth = true) {
    if (!mustAuth) {
      return axios.post(API_URL + url, {}, {});
    }
    if (this.isStillLogin()) {
      return axios.post(API_URL + url, {}, { headers: authHeader() });
    }
  }

  checkStatusNextransTrx(url, data, mustAuth = true) {
    if (!mustAuth) {
      return axios.post(API_URL + url, data, {});
    }
    if (this.isStillLogin()) {
      return axios.post(API_URL + url, data, { headers: authHeader() });
    }
  }
}

export default new RequestService();
