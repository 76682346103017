<template>
  <div
    class="mb-xl-0 mb-4"
    :class="customCols ? customCols : 'col-xl-3 col-sm-4'"
  >
    <div
      class="card"
      :class="isWarning ? 'bg-warning' : 'bg-gray-300'"
      style="height: 105px"
    >
      <div class="card-body p-3">
        <div v-if="loading">
          <div class="text-center">
            <img
              width="10"
              class="img-fluid"
              :src="imageLoading"
              style="width: 80px; height: 50px"
            />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-2" v-if="isWarning">
            <div class="icon icon-shape border-radius-md">
              <i class="fa-solid fa-exclamation-triangle"></i>
            </div>
          </div>
          <div :class="isWarning ? 'col-6' : 'col-8'">
            <div class="numbers">
              <router-link
                v-if="menulink"
                :to="{ path: menulink }"
                class="nav-link"
              >
                <v-hover v-slot="{ isHovering, props }">
                  <p
                    v-bind="props"
                    class="text-sm mb-0 text-capitalize font-weight-bold"
                    :class="isHovering ? 'text-blue' : undefined"
                  >
                    {{ title }}
                  </p>
                </v-hover>
              </router-link>
              <p v-else class="text-sm mb-0 text-capitalize font-weight-bold">
                {{ title }}
              </p>
              <h6
                class="font-weight-bold mb-0"
                :class="isWarning ? 'text-white' : ''"
              >
                {{ value }}
              </h6>
              <span class="text-black font-weight-light" style="width: 6rem">{{
                subvalue
              }}</span>
            </div>
          </div>
          <div class="text-end" :class="isWarning ? 'col-2' : 'col-4'">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              location="end"
            >
              <template v-slot:activator="{ props }">
                <button
                  class="v-btn v-btn--elevated v-theme--light icon icon-shape bg-gradient-primary shadow text-center border-radius-md"
                  v-bind="props"
                >
                  <i :class="classname" v-if="classname"></i>
                  <v-avatar size="36px" v-if="imgsrc" class="mt-2">
                    <v-img alt="Avatar" :src="imgsrc"></v-img>
                  </v-avatar>
                </button>
              </template>

              <v-card max-width="300" :text="tooltipMessage" :color="color">
              </v-card>
            </v-menu>
          </div>
        </div>
        <div class="row" v-if="seeDetail">
          <div class="col-8">
            <v-btn prepend-icon="mdi-eye" variant="text">
              <template v-slot:prepend>
                <v-icon color="success"></v-icon>
              </template>
              See Detail
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "TopShapeItem",
  props: {
    title: String,
    value: undefined,
    subvalue: String,
    classname: String,
    loading: Boolean,
    menulink: String,
    imgsrc: String,
    customCols: String,
    tooltipMessage: String,
    isReadyToSettle: Boolean,
    seeDetail: Boolean,
  },
  data: () => ({
    imageLoading: require("@/assets/img/cube_loading.gif"),
    isWarning: false,
    menu: false,
    color: ref("yellow"),
  }),
  watch: {
    value: {
      immediate: false,
      deep: true,
      handler() {
        let val = parseInt(this.value.replaceAll(",", ""));
        if (this.isReadyToSettle && val < 25000000) {
          this.isWarning = true;
        }
        console.log("warning", this.isWarning);
      },
    },
  },
};
</script>

<style scoped></style>
